import { REACT_APP_PL_APP_BASE_URL } from "@constants/env.constants";
import {
  FilterOption,
  FilterOptionsServer,
  OfferStatus,
  OFFER_FILTER_KEYS
} from "@constants/offers";

export const getOfferStatusArray = (offerType: string): OfferStatus[] => {
  let status = [];
  switch (offerType) {
    case "pending":
      status = [OfferStatus.pending];
      break;
    case "active":
      status = [OfferStatus.accepted, OfferStatus.countered];
      break;
    case "archive":
      status = [
        OfferStatus.rejected,
        OfferStatus.expired,
        OfferStatus.cancelled,
        OfferStatus.closed
      ];
      break;
    case "draft":
      status = [OfferStatus.draft];
      break;

    default:
      status = [
        OfferStatus.pending,
        OfferStatus.accepted,
        OfferStatus.rejected,
        OfferStatus.expired,
        OfferStatus.cancelled,
        OfferStatus.countered,
        OfferStatus.closed,
        OfferStatus.draft
      ];
      break;
  }
  return status;
};

export const appendOfferFilterParams = (param: URLSearchParams, filterOptions: FilterOption) => {
  if (filterOptions.deposit_type) {
    param.append(OFFER_FILTER_KEYS.deposit, filterOptions.deposit_type);
  }
  if (filterOptions.move_in_date_from && filterOptions.move_in_date_to) {
    param.append(
      OFFER_FILTER_KEYS.move_in_date,
      `${filterOptions.move_in_date_from},${filterOptions.move_in_date_to}`
    );
  }
  if (filterOptions.stage && filterOptions.stage.length > 0) {
    param.append(OFFER_FILTER_KEYS.stage, filterOptions.stage.join(","));
  }
  if (filterOptions.term) {
    param.append(OFFER_FILTER_KEYS.term, filterOptions.term);
  }
  if (filterOptions.expires_in_from && filterOptions.expires_in_to) {
    param.append(
      OFFER_FILTER_KEYS.expires_in,
      `${filterOptions.expires_in_from},${filterOptions.expires_in_to}`
    );
  }
};

export const getFilterCount = (filters: FilterOptionsServer) => {
  let count = 0;
  Object.values(filters).forEach((value) => {
    if (value) {
      count += 1;
    }
  });
  return count;
};

export const getDraftOfferLink = (public_id: string, property_id: string) => {
  return `${REACT_APP_PL_APP_BASE_URL}/properties/${property_id}/${public_id}?stage=2`;
};
