// package imports
import React, { useEffect } from "react";
import { IonInput, IonGrid, IonLabel, IonText, IonImg, IonRow } from "@ionic/react";
import { InputChangeEventDetail, TextFieldTypes } from "@ionic/core";

// local imports
import "./InputComp.scss";
import { getImagePath } from "@utils/helpers/media.helper";

// interfaces
interface optionProps {
  showUpperText?: boolean;
  showLowerStatus?: boolean;
  lowerStatus?: string;
  showIcon?: boolean;
  showLabel?: boolean;
  labelName?: string;
  iconName?: string;
}

interface InputProps {
  value: string | number | undefined;
  disabled?: boolean;
  classes?: string;
  styleProps?: Record<string, unknown>;
  color?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  onChange: ((event: CustomEvent<InputChangeEventDetail>) => void) | undefined;
  onBlur?: ((event: CustomEvent<FocusEvent>) => void) | undefined;
  onFocus?: ((event: CustomEvent<FocusEvent>) => void) | undefined;
  options?: optionProps;
  isMandatory?: boolean;
  inputBorderColor?: string;
  type?: TextFieldTypes;
  error?: string;
  isFullWidth?: boolean;
  maxLength?: number;
  inputMode?: "email" | "search" | "tel" | "text" | "url" | "none" | "numeric" | "decimal";
  isAddressDropDown?: boolean;
  defaultValue?: string;
  inputRef?: React.RefObject<HTMLIonInputElement>;
  keyPress?: (event: any) => void;
}

/**
 * Primary UI component for user interaction
 */
export const InputComp = ({
  color,
  classes,
  label,
  name,
  disabled,
  styleProps,
  onChange = () => null,
  onBlur = () => null,
  onFocus = () => null,
  placeholder,
  inputBorderColor,
  isMandatory = false,
  type,
  value,
  isFullWidth = false,
  error,
  options = {},
  maxLength,
  inputMode,
  isAddressDropDown,
  inputRef,
  defaultValue,
  keyPress,
  ...restProps
}: InputProps &
  Partial<
    Omit<HTMLIonInputElement, "translate" | "prefix" | "contentEditable" | "inputMode" | "role">
  >) => {
  useEffect(() => {
    if (inputRef?.current) {
      if (
        (defaultValue !== undefined && !inputRef.current.value) ||
        (defaultValue === "" && inputRef.current.value)
      )
        inputRef.current.value = defaultValue;
    }
  }, [inputRef?.current, defaultValue]);

  return (
    <IonGrid className={`input-wraper ${classes}`} style={styleProps}>
      {options.showLabel && (
        <IonLabel class="label">
          {" "}
          {options.labelName}
          <IonText className="inputs_mandatory">{isMandatory ? "*" : "\u00A0"}</IonText>
        </IonLabel>
      )}
      {options.showUpperText && <IonText class="upper-text">Text </IonText>}
      <IonRow
        className={`${isFullWidth ? "input-block-fullwidth" : `input-block ${inputBorderColor}`} ${
          error ? "input-error" : ""
        }`}
      >
        {/* input-success | input-warning  | input-error */}
        <IonInput
          {...restProps}
          className={`Input ${classes}`}
          type={type}
          placeholder={placeholder}
          color={color}
          name={name}
          disabled={disabled}
          onIonChange={onChange}
          maxlength={maxLength}
          required={isMandatory}
          inputMode={inputMode}
          ref={inputRef}
          defaultValue={defaultValue}
          {...(value ? { value } : {})}
          style={{ textTransform: !isAddressDropDown ? "none" : "uppercase", styleProps }}
          onIonFocus={onFocus ? (e: CustomEvent<FocusEvent>) => onFocus(e) : undefined}
          onIonBlur={onBlur ? (e: CustomEvent<FocusEvent>) => onBlur(e) : undefined}
          onKeyPress={keyPress}
        >
          {label}
        </IonInput>
        {options.showIcon && <IonImg class="input-icon" src={getImagePath(`svg/calendar.svg`)} />}
      </IonRow>
      {error && <IonText className="input_error">{error}</IonText>}
      {options.showLowerStatus && <IonText class="status-text">{options.lowerStatus}</IonText>}
    </IonGrid>
  );
};
