import {
  VIMEO_ACCESS_TOKEN,
  VIMEO_ACTIONS,
  VIMEO_MY_VIDEOS_API_URL,
  VIMEO_VIDEOS_API_URL
} from "@constants/vimeo";

export const getVideoId = (url: string) => url.split("/").pop();
export const getVideoPath = (url: string) => (url ? `/videos/${getVideoId(url)}` : "");

const generateHeaders = (type: string) => {
  const commonHeaders = {
    Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`
  };

  switch (type) {
    case VIMEO_ACTIONS.NEW_VIDEO: {
      const headers = {
        ...commonHeaders,
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        "Content-Type": "application/json"
      };
      return headers;
    }

    case VIMEO_ACTIONS.DELETE_VIDEO: {
      const headers = {
        ...commonHeaders
      };
      return headers;
    }
  }
};

export const createVideoInVimeo = async (fileName: string, fileSize: string) => {
  const fetchResponse = await fetch(VIMEO_MY_VIDEOS_API_URL, {
    method: "post",
    headers: generateHeaders(VIMEO_ACTIONS.NEW_VIDEO),
    body: JSON.stringify({
      name: fileName,
      upload: {
        approach: "tus",
        size: fileSize
      },
      privacy: { view: "anybody" }
    })
  });

  const response = await fetchResponse.json();
  return response;
};

export const deleteVideoFromVimeo = async (videoId: string) => {
  const fetchResponse = await fetch(`${VIMEO_VIDEOS_API_URL}/${videoId}`, {
    method: "delete",
    headers: generateHeaders(VIMEO_ACTIONS.DELETE_VIDEO)
  });

  const response = await fetchResponse.text();
  return response;
};

export const makeQueriedVimeoUrl = (src: string, queries: Record<string, number>) => {
  const newSrc = new URL(src);
  Object.keys(queries).forEach((key: keyof typeof queries) => {
    newSrc.searchParams.append(key, queries[key].toString());
  });
  return newSrc.toString();
};
