// package imports
import React from "react";
import { IonGrid, IonText, IonTextarea } from "@ionic/react";

// local imports
import "./TextAreaInput.scss";

// interfaces
export interface TextAreaProps {
  customClass?: string;
  styleProps?: Record<string, unknown>;
  min?: number;
  max?: number;
  value?: string;
  showCount?: boolean;
  placeholder?: string;
  rows?: number;
  onChange: (value: string | null | undefined) => void;
  autoGrow?: boolean;
  onBlur?: () => void;
  label?: string;
}

export const TextAreaInput = ({
  styleProps,
  customClass,
  autoGrow,
  min,
  max,
  placeholder,
  showCount,
  value,
  rows,
  label,
  onBlur,
  onChange
}: TextAreaProps) => {
  return (
    <IonGrid className={`textarea-wrapper_container ${customClass}`} style={styleProps}>
      {label && <IonText className="label">{label}</IonText>}
      <IonTextarea
        autoGrow={autoGrow}
        className={"textarea"}
        inputmode="text"
        value={value}
        minlength={min}
        maxlength={max}
        placeholder={placeholder}
        rows={rows}
        onBlur={() => {
          onBlur && onBlur();
        }}
        onIonChange={(e) => {
          onChange && onChange(e.detail.value);
        }}
        spellcheck={true}
      />
      {showCount && (
        <IonText className="charCount">
          {value ? value.length : 0} / {max} characters
        </IonText>
      )}
    </IonGrid>
  );
};
