import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown } from "@components/Dropdown/Dropdown";
import { InputComp } from "@components/InputComp/InputComp";
import Popup from "@components/Popup/Popup";
import { TextAreaInput } from "@components/TextAreaInput/TextAreaInput";
import { BUTTON_COLOR_TYPES } from "@constants/enum/button";
import { useAppDispatch, useAppSelector } from "@hooks";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { IonGrid, IonText } from "@ionic/react";
import { toggleReportBugPopup } from "@store/reportBug/reportBugSlice";
import { browserName, deviceType, osName, osVersion } from "react-device-detect";
import "./ReportBugPopup.scss";
import useReportBug from "@hooks/Bug/useReportBug";
import { getLogRocketSessionURLWithTimeout } from "@utils/logRocket/LogRocket";
import { IBugDetails } from "@hooks/Bug/useReportBug";

const ReportBugPopup = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isPopupOpen } = useAppSelector((state) => state.reportBug);
  const { user } = useAppSelector((state) => state.auth);
  const { isMobile } = useWindowDimensions();
  const { mutateAsync: reportBug } = useReportBug();
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [bugTitle, setBugTitle] = useState("");
  const [bugIssue, setBugIssue] = useState("");
  const [bugDescription, setBugDescription] = useState("");

  // function
  const getDisplayName = (
    value: string | null | undefined,
    options: { name: string; value: string }[]
  ) => {
    if (typeof value !== "string") return options[0].name;

    const optionObj = options.find((item) => item.value === value);
    if (optionObj) return optionObj.name;
  };

  const REPORT_BUG_ISSUE_OPTIONS = [
    {
      name: "In existing property or offer process",
      value: "In existing property or offer process"
    },
    { name: "While creating a new property", value: "While creating a new property" },
    { name: "Something else", value: "Something else" }
  ];

  const handleReportABug = async () => {
    setIsLoading(true);
    try {
      let logRocketSessionUrl;
      try {
        logRocketSessionUrl = await getLogRocketSessionURLWithTimeout(5000);
      } catch (error) {
        logRocketSessionUrl = null;
      }

      const systemInfo = {
        device: deviceType,
        browser: browserName,
        os: osName,
        osVersion: osVersion,
        dimensions: {
          height: window.innerHeight,
          width: window.innerWidth
        }
      };
      let bugDetails: IBugDetails = {
        title: bugTitle,
        issue: bugIssue,
        description: bugDescription,
        url: window.location.href
      };

      if (logRocketSessionUrl) {
        bugDetails = { ...bugDetails, sessionUrl: logRocketSessionUrl as string };
      }

      const userDetails = {
        uid: user?.uid || "",
        email: user?.email || "",
        displayName: `${user?.first_name} ${user?.last_name}` || ""
      };

      await reportBug({ systemInfo, bugDetails, userDetails });
    } catch (error) {
      console.log("Error -> ", error);
    } finally {
      setIsLoading(false);
      dispatch(toggleReportBugPopup(false));
      resetPopup();
    }
  };

  const resetPopup = () => {
    setBugTitle("");
    setBugIssue("");
    setBugDescription("");
  };
  return (
    <>
      {!location.pathname.includes("login") && !isMobile && (
        <div
          className={`report-a-bug-cta ${
            location.pathname.startsWith("/ask-morgan") ||
            location.pathname.startsWith("/quantumloopai")
              ? "hidden"
              : null
          }`}
          onClick={() => dispatch(toggleReportBugPopup(true))}
        >
          Report Bug
        </div>
      )}
      <Popup
        title="Report Bug"
        classes={["report-bug-popup"]}
        isOpen={isPopupOpen}
        showCloseIcon
        onClose={() => dispatch(toggleReportBugPopup(false))}
        onDismiss={() => dispatch(toggleReportBugPopup(false))}
        caption={
          <IonGrid className={`report-bug-popup-container`}>
            <IonGrid className="report-bug-input-wrapper">
              <IonText className="report-bug-input-title">
                Title of bug<sup>*</sup>
              </IonText>
              <InputComp
                placeholder="Ex. Applicant cannot place offer"
                isMandatory={true}
                isFullWidth={true}
                type="text"
                value={bugTitle}
                onChange={(e: any) => setBugTitle(e.target.value)}
                name="bugTitle"
              />
            </IonGrid>
            <IonGrid className="report-bug-input-wrapper">
              <IonText className="report-bug-input-title">
                Where is the issue occurring<sup>*</sup>
              </IonText>
              <Dropdown
                options={{
                  listNamesObjectArr: REPORT_BUG_ISSUE_OPTIONS,
                  selectedLocation: getDisplayName(bugIssue, REPORT_BUG_ISSUE_OPTIONS)
                }}
                onChange={(issue) => setBugIssue(issue)}
                optionPlaceholder="Select issue"
              />
            </IonGrid>
            <IonGrid className="report-bug-input-wrapper">
              <IonText className="report-bug-input-title">
                Description<sup>*</sup>
              </IonText>
              <TextAreaInput
                customClass="report-bug-description"
                placeholder={
                  'Please provide address, property reference and/or offer ID, applicant email or agent email associated. Example "9 Canal Path, E2 8BS, 101369 applicant jui.hande@propertyloop.co.uk cannot place an offer. They are getting an error while they click on the button to make an offer." '
                }
                value={bugDescription}
                rows={4}
                min={100}
                max={1000}
                showCount={true}
                onChange={(value: string | null | undefined) => setBugDescription(value || "")}
              />
            </IonGrid>
          </IonGrid>
        }
        actions={[
          {
            label: "Submit",
            isLoading: isLoading,
            onClick: () => handleReportABug(),
            disabled: isLoading || !bugTitle || !bugIssue || !bugDescription,
            color: BUTTON_COLOR_TYPES.dark,
            className: "report-bug-submit"
          }
        ]}
      />
    </>
  );
};

export default ReportBugPopup;
