import * as React from "react";
import { IonRow, IonSpinner } from "@ionic/react";

interface GenericLoaderProps {
  customClass?: string;
  customStylesOnly?: boolean;
}

const GenericLoader = ({ customClass, customStylesOnly = false }: GenericLoaderProps) => {
  return (
    <IonRow
      className={`${customClass ? customClass : ""} ${
        !customStylesOnly ? "justify-content-center align-items-center w-100 h-100" : ""
      }`}
    >
      <IonSpinner />
    </IonRow>
  );
};

export default GenericLoader;
