import {
  FilterOptionsUser,
  SortByOptions,
  UserDataApi,
  UserDetailsTransformed,
  UserTypes,
  USER_FILTER_KEYS
} from "@constants/users";
import moment from "moment";
import { capitalize } from "lodash";

export const getUserFilterCount = (filters: FilterOptionsUser) => {
  let count = 0;
  Object.values(filters).forEach((value) => {
    if (value !== undefined || value !== null) {
      count += 1;
    }
  });
  return count;
};

export const getUserTypeFromString = (userTypeStr: string | null): UserTypes => {
  if (!userTypeStr) return UserTypes.other;
  switch (userTypeStr) {
    case "owner":
      return UserTypes.owner;
    case "renter":
      return UserTypes.renter;
    case "other":
      return UserTypes.other;
    case "all":
      return UserTypes.all;
    default:
      return UserTypes.other;
  }
};

export const getSortByOptionsFromString = (sortBy?: string): SortByOptions => {
  if (!sortBy) return SortByOptions.created;
  switch (sortBy) {
    case "first_name":
      return SortByOptions.name;
    case "email":
      return SortByOptions.email;
    case "mobile":
      return SortByOptions.phone_number;
    case "active_tenancy":
      return SortByOptions.active_tenancy;
    case "role":
      return SortByOptions.user_type;
    case "created_at":
      return SortByOptions.created;
    default:
      return SortByOptions.created;
  }
};

export const transformUserData = (responseData: UserDataApi[]): UserDetailsTransformed[] => {
  const transformedData: UserDetailsTransformed[] = [];
  responseData?.map((data, index) => {
    const hasActiveTenancy = data.tenancy_status === "active" && data.move_out_date;
    transformedData.push({
      id: index,
      user_id: data.id,
      active_tenancy: !hasActiveTenancy
        ? "No"
        : `Yes / ending ${moment(data.move_out_date).format("DD/MM/yyyy")}`,
      created: moment(data.created_at).format("DD/MM/yyyy"),
      email: data.email,
      name: `${data.first_name ?? ""} ${data.last_name ?? ""}`,
      phone_number: `${data?.country_code ?? ""} ${data?.mobile ?? ""}`,
      user_type: capitalize(getUserTypeFromString(data.role)),
      email_verify: data.email_verify,
      phone_verify: data.phone_verify,
      is_disabled: data.is_disabled
    });
  });
  return transformedData;
};

export const appendUserFilterParams = (
  param: URLSearchParams,
  filterOptions: FilterOptionsUser
) => {
  if (filterOptions.active_tenancy !== undefined && [1, 0].includes(filterOptions.active_tenancy)) {
    param.append(USER_FILTER_KEYS.active_tenancy, filterOptions.active_tenancy.toString());
  }

  if (filterOptions.email_verified !== undefined && [1, 0].includes(filterOptions.email_verified))
    param.append(USER_FILTER_KEYS.email_verified, filterOptions.email_verified.toString());

  if (filterOptions?.mobile_verified?.length)
    param.append(USER_FILTER_KEYS.mobile_verified, filterOptions.mobile_verified.join(","));

  if (filterOptions?.user_type?.length) {
    param.append(USER_FILTER_KEYS.user_type, filterOptions.user_type.join(","));
  }

  if (filterOptions?.created?.start && filterOptions?.created?.end) {
    param.append(
      USER_FILTER_KEYS.created,
      `${filterOptions.created.start},${filterOptions.created.end}`
    );
  }
};
