export type FilterKey = "location" | "furnishing" | "propertyType";

export interface CheckboxDataType {
  id: string;
  label: string;
  checked: boolean;
  sever_id: string;
}

export const locationInitState: Array<CheckboxDataType> = [
  {
    id: "pfp-l-1",
    label: "All Locations",
    checked: false,
    sever_id: ""
  },
  {
    id: "pfp-l-2",
    label: "North London",
    checked: false,
    sever_id: "N"
  },
  {
    id: "pfp-l-3",
    label: "East London",
    checked: false,
    sever_id: "E"
  },
  {
    id: "pfp-l-4",
    label: "South East London",
    checked: false,
    sever_id: "SE"
  },
  {
    id: "pfp-l-5",
    label: "South West London",
    checked: false,
    sever_id: "SW"
  },
  {
    id: "pfp-l-6",
    label: "West London",
    checked: false,
    sever_id: "W"
  },
  {
    id: "pfp-l-7",
    label: "North West London",
    checked: false,
    sever_id: "NW"
  },
  {
    id: "pfp-l-8",
    label: "Outer London",
    checked: false,
    sever_id: "O"
  }
];

export const furnishingInitState: Array<CheckboxDataType> = [
  {
    id: "pfp-f-1",
    label: "Furnished",
    checked: false,
    sever_id: "Furnished"
  },
  {
    id: "pfp-f-2",
    label: "Unfurnished",
    checked: false,
    sever_id: "Unfurnished"
  },
  {
    id: "pfp-f-3",
    label: "Flexible",
    checked: false,
    sever_id: "Flexible"
  }
];

export const propertyTypeInitState: Array<CheckboxDataType> = [
  {
    id: "pfp-pt-1",
    label: "Single level",
    checked: false,
    sever_id: "single_level"
  },
  {
    id: "pfp-pt-2",
    label: "Duplex",
    checked: false,
    sever_id: "duplex"
  },
  {
    id: "pfp-pt-3",
    label: "Triplex",
    checked: false,
    sever_id: "triplex"
  },
  {
    id: "pfp-pt-4",
    label: "Terraced",
    checked: false,
    sever_id: "terraced_house"
  },
  {
    id: "pfp-pt-5",
    label: "End of terrace",
    checked: false,
    sever_id: "end_of_terrace"
  },
  {
    id: "pfp-pt-6",
    label: "Detached",
    checked: false,
    sever_id: "detached"
  },
  {
    id: "pfp-pt-7",
    label: "Semi-detached",
    checked: false,
    sever_id: "semi_detached"
  },
  {
    id: "pfp-pt-8",
    label: "Bungalow",
    checked: false,
    sever_id: "bungalow"
  },
  {
    id: "pfp-pt-9",
    label: "Cottage",
    checked: false,
    sever_id: "cottage"
  }
];

export const PROPERTY_FILTER_KEYS = {
  location: "location",
  furnishing: "furnishing",
  propertyType: "property_type",
  move_in_date: "move_in_date",
  no_max: "max_price_range",
  no_min: "min_price_range",
  pets: "pets",
  parking: "parking",
  bathroom: "bathrooms",
  bedroom: "bedrooms"
};

export interface FilterOptionsServer {
  location?: string;
  furnishing?: string;
  property_type?: string;
  move_in_date?: string;
  max_price_range?: string;
  min_price_range: string;
  pets: string;
  parking: string;
  bathrooms: string;
  bedrooms: string;
}

const PRICE_RANGE = [
  {
    name: "£ 100 pcm",
    value: "100"
  },
  {
    name: "£ 500 pcm",
    value: "500"
  },
  {
    name: "£ 1,000 pcm",
    value: "1000"
  },
  {
    name: "£ 5,000 pcm",
    value: "5000"
  },
  {
    name: "£ 10,000 pcm",
    value: "10000"
  },
  {
    name: "£ 20,000 pcm",
    value: "20000"
  },
  {
    name: "£ 30,000 pcm",
    value: "30000"
  },
  {
    name: "£ 50,000 pcm",
    value: "50000"
  }
];

const SALE_PRICE_RANGE = [
  {
    name: "£ 10000",
    value: "10000"
  },
  {
    name: "£ 50000",
    value: "50000"
  },
  {
    name: "£ 100,000",
    value: "100000"
  },
  {
    name: "£ 500,000",
    value: "500000"
  },
  {
    name: "£ 1,000,000",
    value: "1000000"
  },
  {
    name: "£ 2,000,000",
    value: "2000000"
  },
  {
    name: "£ 3,000,000",
    value: "3000000"
  },
  {
    name: "£ 4,000,000",
    value: "4000000"
  },
  {
    name: "£ 5,000,000",
    value: "5000000"
  },
  {
    name: "£ 5,000,000",
    value: "5000000"
  },
  {
    name: "£ 10,000,000",
    value: "10000000"
  },
  {
    name: "£ 100,000,000",
    value: "100000000"
  },
  {
    name: "£ 500,000,000",
    value: "500000000"
  },
  {
    name: "£ 1,000,000,000",
    value: "1000000000"
  }
];

export const PROPERTY_FILTER_MIN_PRICE_RANGE = [
  {
    name: "No min",
    value: "No min"
  },
  ...PRICE_RANGE
];

export const PROPERTY_FILTER_MAX_PRICE_RANGE = [
  {
    name: "No max",
    value: "No max"
  },
  ...PRICE_RANGE
];
export const SALE_PROPERTY_FILTER_MIN_PRICE_RANGE = [
  {
    name: "No min",
    value: "No min"
  },
  ...SALE_PRICE_RANGE
];

export const SALE_PROPERTY_FILTER_MAX_PRICE_RANGE = [
  {
    name: "No max",
    value: "No max"
  },
  ...SALE_PRICE_RANGE
];
