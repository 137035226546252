import { createSlice } from "@reduxjs/toolkit";

interface ApplicantEnquirySlice {
  isSingleEditableAccordionMounted: boolean;
}

const initialState: ApplicantEnquirySlice = {
  isSingleEditableAccordionMounted: false
};

export const applicantEnquirySlice = createSlice({
  name: "applicantEnquiry",
  initialState,
  reducers: {
    setIsSingleEditableAccordionMounted: (state, action) => {
      state.isSingleEditableAccordionMounted = action.payload;
    }
  }
});

export const { setIsSingleEditableAccordionMounted } = applicantEnquirySlice.actions;
export default applicantEnquirySlice.reducer;
