import EnvVars from "./EnvVars.json";

const getMissingEnvVars = (): Array<string> => {
  const missingEnvVars: Array<string> = [];
  const requiredVars: Array<string> = EnvVars.vars;

  requiredVars.forEach((envVar) => {
    if (!process.env[envVar]) {
      missingEnvVars.push(envVar);
    }
  });

  return missingEnvVars;
};

export default getMissingEnvVars;
