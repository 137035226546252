export const MICROSOFT_PROVIDER = "microsoft.com";

export const PASSWORD_PROVIDER = "password";

export type FIREBASE_PROVIDERS = typeof PASSWORD_PROVIDER | typeof MICROSOFT_PROVIDER;

export enum FIREBASE_SSO_ERROR_CODES {
  POPUP_CLOSED_BY_USER = "auth/popup-closed-by-user",
  CREDENTIALS_ALREADY_IN_USE = "auth/credential-already-in-use",
  EMAIL_ALREADY_IN_USE = "auth/email-already-in-use",
  WRONG_PASSWORD = "auth/wrong-password",
  USER_NOT_FOUND = "auth/user-not-found",
  POPUP_BLOCKED = "auth/popup-blocked",
  ACCOUNT_EXISTS_WITH_DIFF_CRED = "auth/account-exists-with-different-credential"
}
