import { AccessUserPermissions } from "./enum/access-user-roles-permissions.enum";
import { TableDataType, TableHeaderDataProps } from "./table";

export interface FilterOptionsCreatedUser {
  start?: string;
  end?: string;
}
export interface FilterOptionsUser {
  user_type?: UserTypes[];
  active_tenancy?: number;
  email_verified?: number;
  mobile_verified?: number[];
  created?: FilterOptionsCreatedUser;
}

export interface Sort {
  sortBy?: SortByOptions;
  sortOrder?: SortOrderOptions;
}

export enum SortOrderOptions {
  ASC = "asc",
  DESC = "desc"
}

export enum SortByOptions {
  name = "first_name",
  email = "email",
  phone_number = "mobile",
  active_tenancy = "active_tenancy",
  user_type = "role",
  created = "created_at"
}

export interface PaginationInterface {
  page?: number;
  limit?: number;
}

export enum UserTypes {
  owner = "owner",
  renter = "renter",
  other = "other",
  all = "all"
}

export interface UserDataApi {
  block_count: number;
  country_code: string;
  created_at: Date;
  email: string;
  email_verify: number;
  first_name: string;
  id: string;
  is_disabled: number;
  last_name: string;
  mobile: string;
  phone_verify: number;
  role: UserTypes;
  tenancy_status: string;
  move_out_date: Date;
}

export interface ResponseData {
  error: string;
  data: UserDataApi[];
  total_count?: number;
  total_pages?: number;
}

export const USER_FILTER_KEYS = {
  user_type: "role",
  active_tenancy: "active_tenancy",
  email_verified: "email_verify",
  mobile_verified: "phone_verify",
  created: "created_at"
};

export const USER_TABLE_HEADER_DISPLAY_NAME = {
  name: "Name",
  user_type: "User type",
  active_tenancy: "Active Tenancy",
  phone_number: "Phone Number",
  email: "Email",
  created: "Created"
};
export const USER_TABLE_API_LABELS = {
  name: "name",
  user_type: "user_type",
  active_tenancy: "active_tenancy",
  phone_number: "phone_number",
  email: "email",
  created: "created"
};

export const USER_TABLE_HEADER: TableHeaderDataProps[] = [
  {
    id: 1,
    displayName: USER_TABLE_HEADER_DISPLAY_NAME.name,
    apiLabel: USER_TABLE_API_LABELS.name,
    sizeXl: "2",
    sizeMd: "2",
    sortable: true,
    sortBy: SortByOptions.name,
    type: TableDataType.name
  },
  {
    id: 2,
    displayName: USER_TABLE_HEADER_DISPLAY_NAME.user_type,
    apiLabel: USER_TABLE_API_LABELS.user_type,
    sizeXl: "1",
    sizeMd: "1",
    sortable: true,
    sortBy: SortByOptions.user_type,
    type: null
  },
  {
    id: 3,
    displayName: USER_TABLE_HEADER_DISPLAY_NAME.active_tenancy,
    apiLabel: USER_TABLE_API_LABELS.active_tenancy,
    sizeXl: "2",
    sizeMd: "2",
    sortable: true,
    sortBy: SortByOptions.active_tenancy,
    type: null
  },
  {
    id: 4,
    displayName: USER_TABLE_HEADER_DISPLAY_NAME.phone_number,
    apiLabel: USER_TABLE_API_LABELS.phone_number,
    sizeXl: "2",
    sizeMd: "2",
    sortable: true,
    sortBy: SortByOptions.phone_number,
    type: TableDataType.mobile
  },
  {
    id: 5,
    displayName: USER_TABLE_HEADER_DISPLAY_NAME.email,
    apiLabel: USER_TABLE_API_LABELS.email,
    sizeXl: "3",
    sizeMd: "3",
    sortable: true,
    sortBy: SortByOptions.email,
    type: TableDataType.email
  },
  {
    id: 6,
    displayName: USER_TABLE_HEADER_DISPLAY_NAME.created,
    apiLabel: USER_TABLE_API_LABELS.created,
    sizeXl: "1.5",
    sizeMd: "1.5",
    sortable: true,
    sortBy: SortByOptions.created,
    type: TableDataType.created
  },
  {
    id: 7,
    displayName: "",
    apiLabel: USER_TABLE_API_LABELS.created,
    sizeXl: ".25",
    sizeMd: ".25",
    sortable: false,
    sortBy: undefined,
    type: TableDataType.action
  }
];

export interface UserDetailsTransformed {
  id: number;
  user_id: string;
  name: string;
  user_type: string;
  active_tenancy: string;
  phone_number: string;
  email: string;
  created: string;
  email_verify: number;
  phone_verify: number;
  is_disabled: number;
}

export const USERS_PERMISSIONS = [
  AccessUserPermissions.ALL_USERS_DATA_READ,
  AccessUserPermissions.ALL_USERS_DATA_UPDATE,
  AccessUserPermissions.ALL_USERS_IMPERSONATE,
  AccessUserPermissions.PROPERTY_LANDLORD_IMPERSONATE
];

export const BLOCK_TEXT =
  "This will suspend their account. They will be notified and can only be unblocked after an internal review";
export const UNBLOCK_TEXT = "The users account will be made active";
