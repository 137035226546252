import apiService from "@utils/apiService";

/**
 * Uploads files to given url and accepts callback for upload progress
 * @param url: API endpoint
 * @param formData: FormData
 * @param onUploadProgress: callback function which will receive upload progress
 * @returns promise of axios post request
 */
const useUploadFile = (
  url: string,
  formData: FormData,
  onUploadProgress: (progressEvent: any) => void
) => {
  return apiService.post(url, formData, {
    onUploadProgress
  });
};

export default useUploadFile;
