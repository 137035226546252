import { createSlice } from "@reduxjs/toolkit";

interface IPropertyRightPaneSlice {
  isSingleEditableAccordionMounted: boolean;
}

const initialState: IPropertyRightPaneSlice = {
  isSingleEditableAccordionMounted: false
};

export const propertyRightPaneSlice = createSlice({
  name: "propertyRightPane",
  initialState,
  reducers: {
    setIsSingleEditableAccordionMounted: (state, action) => {
      state.isSingleEditableAccordionMounted = action.payload;
    }
  }
});

export const { setIsSingleEditableAccordionMounted } = propertyRightPaneSlice.actions;
export default propertyRightPaneSlice.reducer;
