// package imports
import React from "react";
import { IonButton, IonImg } from "@ionic/react";

// local imports
import "./button.scss";
import GenericLoader from "@components/Loader/Generic";
import { getImagePath } from "@utils/helpers/media.helper";
import { BUTTON_COLOR_TYPES } from "@constants/enum/button";

// interfaces
interface optionProps {
  showIcon?: boolean;
}
interface ButtonProps {
  disabled?: boolean;
  classes?: Array<string>;
  color?: BUTTON_COLOR_TYPES;
  size?: "small" | "default" | "large";
  label: string | JSX.Element;
  styleProps?: Record<string, unknown> | object;
  onClick: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  options?: optionProps;
  isLoading?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  color,
  classes,
  label,
  disabled,
  styleProps,
  onClick,
  onBlur = () => null,
  onFocus = () => null,
  size,
  options = {},
  isLoading
}: ButtonProps) => {
  return (
    <IonButton
      type="button"
      size={size}
      className={`btn ${classes?.join(" ")} btn-${color}`}
      disabled={disabled}
      style={{ textTransform: "none", ...styleProps }}
      onClick={onClick}
      onIonBlur={onBlur}
      onIonFocus={onFocus}
      fill="clear"
    >
      {isLoading ? (
        <GenericLoader customClass="btn-loader" />
      ) : (
        <>
          {options.showIcon && (
            <IonImg
              class="btn-icon"
              src={getImagePath(`svg/comment-alt-dots-black.svg`)}
              alt={"Button"}
            />
          )}
          <span>{label}</span>
        </>
      )}
    </IonButton>
  );
};
