const setupLogger = () => {
  if (process.env.REACT_APP_DISABLE_LOGGING === "1") {
    console.error = () => false;
    console.warn = () => false;
    console.debug = () => false;
    console.info = () => false;
    console.log = () => false;
  } else if (process.env.REACT_APP_LOG_LEVEL === "error") {
    console.warn = () => false;
    console.debug = () => false;
    console.info = () => false;
    console.log = () => false;
  } else if (process.env.REACT_APP_LOG_LEVEL === "warn") {
    console.debug = () => false;
    console.info = () => false;
    console.log = () => false;
  } else if (process.env.REACT_APP_LOG_LEVEL === "log") {
    return false;
  }
};

export default setupLogger;
