// package imports
import React from "react";
import { IonGrid, IonCol, IonRow, IonText } from "@ionic/react";

// local imports
import { Button } from "@components/Button/Button";
import { InputComp } from "@components/InputComp/InputComp";
import { LoginOptions } from "@components/LoginOptions/LoginOptions";
import "./login.scss";
import { getImagePath } from "@utils/helpers/media.helper";
import {
  FIREBASE_PROVIDERS,
  MICROSOFT_PROVIDER,
  PASSWORD_PROVIDER
} from "@constants/firebase_providers.constants";

interface FormData {
  email: string;
  password: string;
  error: Record<string, string>;
}
interface LoginProps {
  classes?: string;
  styleProps?: Record<string, unknown>;
  formData: FormData;
  onChange: (field: string, value: string | undefined | null) => void;
  handleFirebaseLogin: (method: FIREBASE_PROVIDERS) => void;
  loginError: string | null;
}
/**
 * Primary UI component for user interaction
 */
const Login = ({
  classes,
  styleProps,
  formData,
  onChange,
  handleFirebaseLogin,
  loginError
}: LoginProps) => {
  const loginOptions = [
    {
      label: "phone",
      icon: "phone",
      onClick: () => null,
      hide: true
    },
    {
      label: "google",
      icon: "google",
      onClick: () => null,
      hide: true
    },
    {
      label: "facebook",
      icon: "facebook",
      onClick: () => null,
      hide: true
    },
    {
      label: "microsoft",
      icon: "microsoft",
      onClick: () => {
        handleFirebaseLogin(MICROSOFT_PROVIDER);
      },
      hide: false
    }
  ];

  return (
    <IonGrid className={`login-page ${classes || ""}`} style={styleProps}>
      <IonRow className="login-wrapper">
        <IonRow className="login-header">
          <IonCol className="lgh-avatar">
            <img src={getImagePath(`svg/user-white.svg`)} alt="user" />
          </IonCol>
          <IonCol>
            <IonGrid className="lgh-title"> Log in </IonGrid>
            <IonGrid className="lgh-signUp">
              {" "}
              Don’t have an account? <a href="login.html"> Sign up</a>{" "}
            </IonGrid>
          </IonCol>
        </IonRow>
        <IonRow className="lgn-input_block">
          <IonCol>
            <InputComp
              placeholder="Enter Email"
              isMandatory={false}
              type="text"
              options={{
                labelName: "Email",
                showLabel: true
              }}
              value={formData.email}
              onChange={(e) => onChange("email", e.detail.value)}
              error={formData.error.email}
            />
          </IonCol>
        </IonRow>
        <IonRow className="lgn-input_block">
          <IonCol>
            <InputComp
              placeholder="Enter Password"
              isMandatory={false}
              type="password"
              options={{
                labelName: "Password",
                showLabel: true
              }}
              value={formData.password}
              onChange={(e) => onChange("password", e.detail.value)}
              error={formData.error.password}
            />
          </IonCol>
        </IonRow>
        <IonRow className="lgn-submit_btn">
          <IonCol>
            <Button
              label="Continue"
              size="default"
              onClick={() => handleFirebaseLogin(PASSWORD_PROVIDER)}
              classes={["btn-black"]}
            />
          </IonCol>
        </IonRow>
        <IonRow className="lgn-or_divider">
          <h4>
            <span>or</span>
          </h4>
        </IonRow>
        <IonRow className="lgn-options">
          {loginOptions.map((item) => (
            <LoginOptions
              key={item.label}
              iconOption={item.icon}
              optionName={item.label}
              onClick={item.onClick}
              hide={item.hide}
            />
          ))}
        </IonRow>
        {loginError && (
          <IonRow className="mt_8">
            <IonText className="danger_text">{loginError}</IonText>
          </IonRow>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default Login;
