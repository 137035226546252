import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "@hooks";
import { isLoggedInFromStore } from "@store/auth/authSlice";
import ExpandableNavbar from "@layouts/ExpandableNavbar/ExpandableNavbar";

function WebProtectedRoute({ children }: { children?: React.ReactElement | undefined }) {
  const location = useLocation();
  const isLoggedIn = useAppSelector(isLoggedInFromStore);
  if (!isLoggedIn) return <Navigate to="/login" state={{ from: location }} replace />;
  return !children ? (
    <>
      <ExpandableNavbar />
      <Outlet />
    </>
  ) : (
    children
  );
}

export default WebProtectedRoute;
