import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import toast from "react-hot-toast";

import { BASE_API_URL } from "@constants/env.constants";
import { TWENTY_FOUR_HOURS_IN_MS } from "@constants/time.constants";
import { clearStorage, getObject, storeTokenInLocalStorage } from "@utils/localStorage";

class ApiService {
  constructor() {
    // listener to update token in axios header when token changes in localstorage in other open tabs
    window.addEventListener("storage", async () => {
      const localStorageToken: string | null = await getObject("tid");
      if (localStorageToken) {
        axios.defaults.headers.common["authorization"] = `bearer ${localStorageToken}`;
      }
    });

    axios.interceptors.response.use(
      (res) => {
        // Save last active time of the user in local storage
        window.localStorage.setItem("lastActiveTime", new Date().getTime().toString());
        return res;
      },
      (err: AxiosError) => {
        if (err.response?.status !== 401) {
          // Save last active time of the user in local storage
          window.localStorage.setItem("lastActiveTime", new Date().getTime().toString());
        }
        if (err.response?.status === 500) toast.error(err.response.data.message);
        else if (err.message && err.response?.status !== 401) toast.error(err.message);
        throw err;
      }
    );
  }

  // On full page reload, firebase.auth().currentUser is null
  // This function polls for the current user until it is not null
  // It will resolve the promise after 5 attempts (maximum)
  private pollForFirebaseUser(): Promise<firebase.User | null> {
    return new Promise((resolve) => {
      let attemptNumber = 0;
      const maxAttempts = 5;
      const intervalTime = 1000; // 1 second

      const interval = setInterval(() => {
        attemptNumber++;

        const currentUser = firebase.auth().currentUser;

        if (currentUser) {
          clearInterval(interval);
          resolve(currentUser);
        }

        if (attemptNumber >= maxAttempts) {
          clearInterval(interval);
          resolve(null);
        }
      }, intervalTime);
    });
  }

  private async handleRequest(requestFn: () => Promise<AxiosResponse>) {
    try {
      return await requestFn();
    } catch (error) {
      // If it's not an axios error or not 401 error, throw the error
      if (!axios.isAxiosError(error) || error.response?.status !== 401) throw error;

      const currentTimeInMs = new Date().getTime();
      const lastActiveTimeInMs = parseInt(window.localStorage.getItem("lastActiveTime") || "0");

      const currentUser = await this.pollForFirebaseUser();

      // Fetch new token and retry request if:
      // less than 24 hours have passed since last active time
      // and current user is not null
      if (lastActiveTimeInMs + TWENTY_FOUR_HOURS_IN_MS > currentTimeInMs && currentUser) {
        await currentUser.reload();
        const tokenResult = await currentUser.getIdTokenResult(true);
        await storeTokenInLocalStorage({
          token: tokenResult?.token,
          expirationTime: tokenResult?.expirationTime.toString()
        });
        this.setHeader("authorization", `bearer ${tokenResult?.token}`);
        return await requestFn();
      } else {
        await firebase.auth().signOut();
        await clearStorage();
        window.location.replace("/login");
        // Return statement is needed to avoid TS error
        return {} as AxiosResponse;
      }
    }
  }

  async get(url: string, config: AxiosRequestConfig = {}) {
    return this.handleRequest(() => axios.get(BASE_API_URL + url, config));
  }
  delete(url: string, config: AxiosRequestConfig = {}) {
    return this.handleRequest(() => axios.delete(BASE_API_URL + url, config));
  }
  post(url: string, payload?: Record<string, unknown> | FormData, config: AxiosRequestConfig = {}) {
    return this.handleRequest(() => axios.post(BASE_API_URL + url, payload, config));
  }
  put(url: string, payload: Record<string, unknown>, config: AxiosRequestConfig = {}) {
    return this.handleRequest(() => axios.put(BASE_API_URL + url, payload, config));
  }
  patch(url: string, payload?: Record<string, unknown>, config: AxiosRequestConfig = {}) {
    return this.handleRequest(() => axios.patch(BASE_API_URL + url, payload, config));
  }
  setHeader(headerName: string, value: string) {
    axios.defaults.headers.common[headerName] = value;
  }
}

const apiService = new ApiService();
export default apiService;
