import useEventListener from "@hooks/useEventListener";
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import { getImagePath } from "@utils/helpers/media.helper";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import "./ExpandableNavbar.scss";
import { MenuOption, menuOptions } from "./Navigation";
import { useAppDispatch, useAppSelector } from "@hooks";
import { customUuid } from "@utils/messages";
import { UserEventHandler } from "@sendbird/chat";
import { USERS_PERMISSIONS } from "@constants/users";
import { ReactQueriesKeys } from "@constants/enum/react-queries-keys.enum";
import { logout } from "@store/auth/authSlice";
import { useQueryClient } from "react-query";
import { toggleReportBugPopup } from "@store/reportBug/reportBugSlice";
import { getIsPermissionGranted } from "@utils/helpers";

function ExpandableNavbar() {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [CurrentPage, setCurrentPage] = useState<string>("");
  const [totalUnreadMessageCount, setTotalUnreadMessageCount] = useState(0);
  const [navItems, setNavItems] = useState<MenuOption[]>(menuOptions);
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const expandableNavbarRef = useRef<HTMLIonGridElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const sbInstance = useAppSelector((state) => state.messages.sbInstance);
  const isUsersPermissionsGranted = getIsPermissionGranted(
    user?.accessControl || [],
    USERS_PERMISSIONS
  );
  const loggedInUserId = user?.uid;
  const profileImage = user?.avatar_url;
  const profileInitials = user
    ? `${user.first_name?.[0]?.toUpperCase()}${user.last_name?.[0].toUpperCase()}`
    : "";
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const handleNavbarOutsideClick = (event: MouseEvent | null) => {
    if (
      expandableNavbarRef.current &&
      !expandableNavbarRef.current.contains(event?.target as Element)
    ) {
      setIsExpanded(false);
    }
  };

  useEventListener("mousedown", handleNavbarOutsideClick, [expandableNavbarRef], window);

  // Track the page and the icons highlighted on side nav
  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location.pathname]);

  // Unread message count from send-bird api
  useEffect(() => {
    (async () => {
      try {
        if (loggedInUserId && sbInstance) {
          const uniqueId = customUuid();

          const totalUnreadMessageCount =
            await sbInstance.groupChannel.getTotalUnreadMessageCount();

          if (totalUnreadMessageCount) {
            setTotalUnreadMessageCount(totalUnreadMessageCount);
          }

          const userEventHandler: UserEventHandler = new UserEventHandler({
            onTotalUnreadMessageCountUpdated: (totalCount: number) => {
              setTotalUnreadMessageCount(totalCount);
            }
          });

          sbInstance.addUserEventHandler(uniqueId, userEventHandler);
        }
      } catch (error) {
        console.error("SendBird unread message count error", error);
      }
    })();
  }, [loggedInUserId, sbInstance]);

  const ExpandableNavItem = (navItem: MenuOption) => {
    const {
      navItemName: parentItemName,
      id: parentId,
      isAvailable: parentIsAvailable,
      imgSrc,
      imgAlt,
      subOptions,
      isCollapsed
    } = navItem;
    const allSubOptionsPaths = subOptions?.map((s) => s.path);
    let shouldHighlightParent = false;
    if (allSubOptionsPaths?.length)
      for (let i = 0; i < allSubOptionsPaths?.length; i++) {
        const so = allSubOptionsPaths[i];
        if (so && CurrentPage.includes(so)) {
          shouldHighlightParent = true;
          break;
        }
      }
    let extraClassNames = ``;
    if (shouldHighlightParent) extraClassNames += "current ";
    if (!isCollapsed) extraClassNames += "open ";

    return (
      <IonCol
        className={`nbwc-menu_item ${extraClassNames}
        unCollapsed item-collapsible`}
        key={parentId}
      >
        <IonGrid
          className={`menu_items ${!parentIsAvailable ? "disabled" : ""}`}
          onClick={() => {
            const newNavItems = navItems.map((m) => {
              if (m.isExpandable && m.id === navItem.id) {
                m.isCollapsed = !m.isCollapsed;
              }
              return m;
            });

            setNavItems(newNavItems);
          }}
        >
          <span className="msgIcon">
            <img src={imgSrc} alt={imgAlt} />
          </span>

          <IonGrid className="submenu-tooltip">
            <IonRow className="submenu-parent-item">{parentItemName}</IonRow>
            {subOptions &&
              subOptions.map((so) => {
                if (so.isVisibleToAdminOnly && !isUsersPermissionsGranted) return;
                return (
                  <IonRow
                    className={`submenu-child-item ${
                      !so.isAvailable || (so.path === "/deals" && !user?.agent_hs_deal_url)
                        ? "coming-soon"
                        : "hover"
                    }`}
                    key={so.id}
                    onClick={
                      !so.isAvailable || (so.path === "/deals" && !user?.agent_hs_deal_url)
                        ? undefined
                        : () => {
                            if (so.path === "/offers")
                              queryClient.removeQueries(ReactQueriesKeys.Offers);
                            if (so.path) navigate(so.path);
                            setIsExpanded(false);
                          }
                    }
                  >
                    {so.navItemName}
                  </IonRow>
                );
              })}
          </IonGrid>

          <IonGrid className={`item--name`}>
            <span className={`msgText ${!parentIsAvailable ? "coming_soon" : ""} `}>
              {parentItemName}
            </span>
            <img
              src={getImagePath(`svg/arrow-down-fill.svg`)}
              alt="down-arrow"
              className={`down-fill-arrow ${isCollapsed ? "" : "open"}`}
            />
          </IonGrid>
          {shouldHighlightParent && <div className="current--marker" />}
        </IonGrid>

        {subOptions && !isCollapsed && isExpanded && (
          <IonGrid className="subMenu_items">
            <IonGrid className="item-dropdown">
              <ul>
                {subOptions.map((data) => {
                  if (data.isVisibleToAdminOnly && !isUsersPermissionsGranted) return;
                  const highlightSubmenu = data.path && CurrentPage.includes(data.path);
                  return (
                    <li
                      key={data.navItemName}
                      className={highlightSubmenu ? "current" : ""}
                      onClick={
                        !data.isAvailable || (data.path === "/deals" && !user?.agent_hs_deal_url)
                          ? () => {
                              setIsExpanded(false);
                            }
                          : () => {
                              if (data.path === "/offers")
                                queryClient.removeQueries(ReactQueriesKeys.Offers);
                              if (data.path) navigate(data.path);
                              setIsExpanded(false);
                            }
                      }
                    >
                      <IonText
                        className={`${
                          !data.isAvailable || (data.path === "/deals" && !user?.agent_hs_deal_url)
                            ? "coming_soon"
                            : "hover"
                        }`}
                      >
                        {data.navItemName}
                      </IonText>
                      {highlightSubmenu && <div className="current--marker"></div>}
                    </li>
                  );
                })}
              </ul>
            </IonGrid>
          </IonGrid>
        )}
      </IonCol>
    );
  };

  const NavItem = (navItem: MenuOption) => {
    const { navItemName, path, id, isAvailable, imgSrc, imgAlt } = navItem;
    const newPath = path?.split("?")[0];
    const isComingSoon = !isAvailable || (path === "/tasks" && !user?.agent_hs_meeting_url);
    return (
      <IonCol
        className={`nbwc-menu_item ${
          newPath &&
          ((CurrentPage === "/" && newPath === "/") ||
            (CurrentPage !== "/" && newPath !== "/" && CurrentPage.includes(newPath)))
            ? "current"
            : ""
        }`}
        key={id}
        onClick={() => {
          setIsExpanded(false);
          if (path) {
            if (id === 2) {
              if (user?.agent_hs_meeting_url) navigate(path);
              return;
            }
            navigate(path);
            return;
          }
        }}
      >
        <IonGrid className={`menu_items ${isComingSoon ? "disabled" : ""}`}>
          <span className="msgIcon">
            <img src={imgSrc} alt={imgAlt} />
            {navItemName === "Inbox" && totalUnreadMessageCount ? (
              <span className="msgIcCounter"></span>
            ) : null}
          </span>

          <div className={`menu_item_tooltip ${isComingSoon ? "coming-soon" : ""}`}>
            <IonText>{navItemName}</IonText>
            {isComingSoon && <span className="coming_soon_text">COMING SOON</span>}
          </div>

          <IonGrid className={`item--name`}>
            <span className={`msgText ${isComingSoon ? "coming_soon" : ""} `}>
              {navItemName}
              {navItemName === "Messages" && totalUnreadMessageCount ? (
                <span className="msgCounter">{totalUnreadMessageCount}</span>
              ) : null}
            </span>
          </IonGrid>
          {newPath &&
            ((CurrentPage === "/" && newPath === "/") ||
              (CurrentPage !== "/" && newPath !== "/" && CurrentPage.includes(newPath))) && (
              <div className="current--marker" />
            )}
        </IonGrid>
      </IonCol>
    );
  };

  const navbarLogo = () => {
    return (
      <IonRow className="nbwc-logo">
        <IonGrid className="nbwc-logo_wrapper">
          {isExpanded ? (
            <img
              src={getImagePath(`svg/propertyloop-logo-white.svg`)}
              alt="propertyloop icon"
              className="pl_icon logo-pointer"
              onClick={() => navigate("/")}
            />
          ) : (
            <img
              src={getImagePath(`svg/pl-icon.svg`)}
              alt="propertyloop icon"
              className="pl_icon logo-pointer"
              onClick={() => navigate("/")}
            />
          )}
        </IonGrid>
        <IonGrid className="burger_icon d-sm">
          {isExpanded ? (
            <img
              src={getImagePath(`svg/cross_icon.svg`)}
              alt="cross icon"
              onClick={toggleExpansion}
            />
          ) : (
            <img
              src={getImagePath(`svg/burger-icon.svg`)}
              alt="burger icon"
              onClick={toggleExpansion}
            />
          )}
        </IonGrid>
      </IonRow>
    );
  };

  const renderArrow = (isExpanded: boolean) => (
    <IonCol className="nbwc-menu_item show_less_more">
      {isExpanded && <IonText className="item--name"></IonText>}
      <img
        src={getImagePath(`png/double-left.png`)}
        alt={isExpanded ? "right arrow" : "left arrow"}
      />
    </IonCol>
  );

  const upperMenu = () => {
    return (
      <IonRow className="nbwc-upper_menu">
        {navItems.map((item) => {
          if (item.isVisibleToAdminOnly && !isUsersPermissionsGranted) return;
          return item.isExpandable && item.subOptions?.length
            ? ExpandableNavItem(item)
            : NavItem(item);
        })}
      </IonRow>
    );
  };

  const lowerMenu = () => {
    return (
      <IonRow className="nbwc-lower_menu">
        {isMobile && (
          <IonCol
            className={`nbwc-menu_item`}
            onClick={() => {
              dispatch(toggleReportBugPopup(true));
              setIsExpanded(false);
            }}
          >
            <img src={getImagePath(`svg/bug.svg`)} alt="bug" />
            {(isExpanded || isMobile) && (
              <IonText className="item--name">
                <span className={`msgText`}>Report Bug</span>
              </IonText>
            )}
          </IonCol>
        )}
        <IonCol
          className={`nbwc-menu_item help_menu`}
          onClick={() => {
            navigate("/help");
            setIsExpanded(false);
          }}
        >
          <img src={getImagePath(`svg/question.svg`)} alt="help" />
          {(isExpanded || isMobile) && (
            <IonText className="item--name">
              <span className={`msgText`}>Help</span>
            </IonText>
          )}
        </IonCol>
        <IonCol
          className="nbwc-menu_item "
          onClick={() => {
            navigate("/profile");
            setIsExpanded(false);
          }}
        >
          <div className="profile_img">
            {profileImage ? (
              <img src={profileImage} alt="profile" />
            ) : (
              <IonText className="profile_initial">{profileInitials}</IonText>
            )}
          </div>
          {(isExpanded || isMobile) && <IonText className="item--name">{user?.first_name}</IonText>}
        </IonCol>
        {(isExpanded || isMobile) && (
          <IonCol
            className="nbwc-menu_item "
            onClick={() => {
              const reactQueryKeysArray = Object.values(ReactQueriesKeys);
              reactQueryKeysArray.forEach((key) => {
                queryClient.removeQueries(key);
              });
              dispatch(logout());
              navigate("/login", { replace: true });
            }}
          >
            <img src={getImagePath(`svg/logout.svg`)} alt="logout" />
            {(isExpanded || isMobile) && <IonText className="item--name">Log out</IonText>}
          </IonCol>
        )}
        {!isMobile && (
          <IonRow className="nbwc-expand-menu" onClick={toggleExpansion}>
            {renderArrow(isExpanded)}
          </IonRow>
        )}
      </IonRow>
    );
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <>
          <IonGrid className="mobileNav">
            <IonGrid className="nbwc-logo_wrapper">
              <img
                src={getImagePath(`svg/propertyloop-logo-white.svg`)}
                alt="propertyloop icon"
                className="pl_icon logo-pointer"
                onClick={() => navigate("/")}
              />
            </IonGrid>
            <IonGrid className="burger_icon d-md-sm md hydrated">
              {isExpanded ? (
                <img
                  src={getImagePath(`svg/cross_icon.svg`)}
                  alt="cross icon"
                  onClick={toggleExpansion}
                />
              ) : (
                <img
                  src={getImagePath(`svg/burger-icon.svg`)}
                  alt="burger icon"
                  onClick={toggleExpansion}
                />
              )}
            </IonGrid>
          </IonGrid>
          <IonGrid className={`mobileSlideOutMenu ${isExpanded ? "slideIn" : "slideOut"}`}>
            {upperMenu()}
            {lowerMenu()}
          </IonGrid>
          <IonGrid
            className={`black_curtain ${isExpanded ? "show_curtain" : "hide_curtain"} `}
            onClick={() => setIsExpanded(false)}
          />
        </>
      ) : (
        <>
          <IonGrid
            className={`navbar_expansion_component ${isExpanded ? "expand" : "shrink"}`}
            ref={expandableNavbarRef}
          >
            {navbarLogo()}
            {upperMenu()}
            {lowerMenu()}
          </IonGrid>
        </>
      )}
    </React.Fragment>
  );
}

export default ExpandableNavbar;
