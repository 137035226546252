import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "./App.scss";
import getMissingEnvVars from "@utils/envVars/EnvVars";
import { setLogRocketHeader, setupLogRocket, setLogRocketUser } from "@utils/logRocket/LogRocket";
import setupLogger from "@utils/logger/Logger";
import WebRoutes from "./WebRoutes";
import { firebaseConfig } from "@utils/firebase";
import { setAuthUser } from "./setAuthUser";
import { useAppDispatch, useAppSelector } from "@hooks";
import { setAuthenticated } from "@store/auth/authSlice";
import GenericLoader from "@components/Loader/Generic";
import { Toaster } from "react-hot-toast";
import { validateCreateSingleUser } from "@utils/messages";

function App() {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.auth.isLoading);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const user = useAppSelector((state) => state.auth.user);
  /* this useEffect runs on component mount for setup related tasks */
  useEffect(() => {
    (async () => {
      firebase.initializeApp(firebaseConfig);
      setupLogger();
      setupLogRocket();
      setLogRocketHeader();
      const missingEnvVars = getMissingEnvVars();
      if (missingEnvVars.length > 0) {
        alert(`ERROR: Missing environment variable(s):\n${missingEnvVars.join("\n")}`);
      }
      await setAuthUser(dispatch);
      dispatch(setAuthenticated());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (user?.uid) {
        setLogRocketUser(user);
        await validateCreateSingleUser(user.uid, dispatch);
      }
    })();
  }, [user?.uid]);

  if (isLoading) return <GenericLoader />;
  return (
    <>
      {isAuthenticated && <WebRoutes />}
      <Toaster position={"top-right"} />
    </>
  );
}

export default App;
