import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LazyLoadComponent from "@hoc/LazyLoadComponent";
import WebProtectedRoute from "@hoc/WebProtectedRoute";
import LoginContainer from "@pages/Login/Login.page";
import { ReactQueryDevtools } from "react-query/devtools";
import { IS_LOCAL_ENV } from "@constants/env.constants";
import AskMorganChatBotV2 from "@pages/AskMorgan/AskMorganV2";
import ReportBugPopup from "@components/ReportBugPopup/ReportBugPopup";
import QunatumloopAi from "@pages/QunatumloopAi/QunatumloopAi";
import QunatumloopAiChatOnly from "@pages/QunatumloopAi/QuantumloopAiChatOnly";

const OffersPage = React.lazy(() => import("@pages/Offers/Offers.page"));
const UsersPage = React.lazy(() => import("@pages/Users/Users.page"));
const PropertiesPage = React.lazy(
  () => import("@pages/AgentPropertyDashboardContainer/AgentPropertyDashboardContainer")
);
const SalePropertiesPage = React.lazy(() => import("@pages/SaleProperty/SaleProperty"));
const Profile = React.lazy(() => import("@pages/Profile/Profile.page"));
const MessagesPage = React.lazy(() => import("@pages/Messages/Messages.page"));
const HelpPage = React.lazy(() => import("@pages/Help/Help.page"));
const DealPage = React.lazy(() => import("@pages/Deal/Deal.page"));
const TaskPage = React.lazy(() => import("@pages/Task/Task.page"));
const Home = React.lazy(() => import("@pages/Home/Home.page"));
const Marketing = React.lazy(() => import("@pages/Marketing/Marketing.page"));
const ApplicantsPage = React.lazy(() => import("@pages/Applicants/Applicants.page"));

const WebRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="login" element={<LoginContainer />} />
        <Route path="ask-morgan/:projectId" element={<AskMorganChatBotV2 />} />
        <Route path="quantumloopai/:projectId" element={<QunatumloopAi />} />
        <Route path="quantumloopai-chatonly/:projectId" element={<QunatumloopAiChatOnly />} />

        {/* all auth protected routes will nest inside this route */}
        <Route element={<WebProtectedRoute />}>
          <Route
            path="/profile"
            element={<LazyLoadComponent title="Profile" Component={Profile} />}
          />
          <Route path="/properties/*" element={<LazyLoadComponent Component={PropertiesPage} />} />
          <Route path="/sales/*" element={<LazyLoadComponent Component={SalePropertiesPage} />} />
          {/* Owner and applicant routes removed in PBI 10087 */}
          <Route path="/offers/*" element={<LazyLoadComponent Component={OffersPage} />} />
          <Route path="/users/*" element={<LazyLoadComponent Component={UsersPage} />} />
          <Route path="/messages" element={<LazyLoadComponent Component={MessagesPage} />} />
          <Route path="/help" element={<LazyLoadComponent removePadding Component={HelpPage} />} />
          <Route path="/deals" element={<LazyLoadComponent removePadding Component={DealPage} />} />
          <Route path="/tasks" element={<LazyLoadComponent removePadding Component={TaskPage} />} />
          <Route path="/applicants/*" element={<LazyLoadComponent Component={ApplicantsPage} />} />
          <Route
            path="/marketing"
            element={<LazyLoadComponent removePadding Component={Marketing} />}
          />
          <Route path="/" element={<LazyLoadComponent removePadding Component={Home} />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {IS_LOCAL_ENV && <ReactQueryDevtools initialIsOpen={false} position="top-right" />}
      <ReportBugPopup />
    </>
  );
};

export default WebRoutes;
