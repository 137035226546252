import React, { useEffect, useState } from "react";
import "./PromptSuggestion.scss";
import { IonGrid, IonRow } from "@ionic/react";

interface PromptSuggestionProps {
  prompts: string[];
  handleSendMessageCallback: (prompt: string) => void;
}

function PromptSuggestion({
  handleSendMessageCallback,
  prompts: dantePrompts
}: PromptSuggestionProps) {
  const SEE_MORE = "See more";
  const SEE_LESS = "See less";
  const [prompts, setPrompts] = useState<string[]>(dantePrompts);
  const [isExpanded, setIsExpanded] = useState(false);
  const [disableClick, setDisableClick] = useState(false);

  useEffect(() => {
    if (dantePrompts.length > 2) {
      const newPrompts = isExpanded
        ? [...dantePrompts, SEE_LESS]
        : [dantePrompts[0], dantePrompts[1], SEE_MORE];
      setPrompts(newPrompts);
    }
  }, [isExpanded]);

  const handlePromptClick = (prompt: string) => {
    if (prompt === SEE_MORE || prompt === SEE_LESS) {
      setIsExpanded(prompt === SEE_MORE);
    } else {
      setDisableClick(true);
      handleSendMessageCallback(prompt);
    }
  };

  return (
    <IonGrid className="chatbot-prompt-suggestion">
      <IonRow className="suggestion-wrapper">
        {prompts.map((prompt) => {
          return (
            <div
              className={`suggestion-box ${
                prompt === SEE_MORE || prompt === SEE_LESS ? "action-button" : ""
              }`}
              onClick={disableClick ? () => null : () => handlePromptClick(prompt)}
            >
              <div className="ellipsis-container">{prompt}</div>
            </div>
          );
        })}
      </IonRow>
    </IonGrid>
  );
}

export default PromptSuggestion;
