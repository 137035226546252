import { SingleOfferDetailsAgent } from "@constants";

export const convertRightPaneTabToSearchParam = (tab: string) =>
  tab.split(" ").join("_").toLocaleLowerCase();

export const getAgentsName = (
  agent: SingleOfferDetailsAgent,
  viewingAgent: SingleOfferDetailsAgent
): string => {
  if (!viewingAgent?.agent_id) return `${agent?.first_name || ""} ${agent?.last_name || ""}`;
  if (agent?.agent_id === viewingAgent.agent_id) return `${agent?.first_name} ${agent?.last_name}`;
  return `${agent?.first_name} ${agent?.last_name} / ${viewingAgent?.first_name} ${viewingAgent?.last_name}`;
};
