import { getImagePath } from "@utils/helpers/media.helper";
import { isMobile } from "react-device-detect";
import MsgComponent from "./MsgComponent";
import ReplyComponent from "./ReplyComponent";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BASE_API_URL, REACT_APP_ASK_MORGAN_API_KEY } from "@constants/env.constants";
import {
  IMessage,
  IProjectMetaDataDante,
  checkProjectExist,
  createSessionDante
} from "@utils/ask-morgan";
// import { getObject, setObject } from "@utils/localStorage";
import GenericLoader from "@components/Loader/Generic";
import { IonItem, IonList, IonPopover } from "@ionic/react";
import "./AskMorganV2.scss";
import PromptSuggestion from "./PromptSuggestion";

enum USER_LABEL_VALUE {
  USER = "user",
  CHATBOT = "chatbot"
}

const retryTimeoutMessage =
  "Sorry, we’re currently experiencing high demand. Let’s try that again for you...";
const timeoutMessage =
  "We’re currently experiencing high demand. Please retry your query shortly. Thank you for your patience!";

const errorMessagesCode = {
  timeoutErrorCode: "error_408"
};

const defaultQuestion =
  "Hello, I am AI Chatbot. Please tell me who you are so that I can provide personalized answers and convert them into better text.";
const AskMorganChatBotV2: React.FC = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const model = queryParams.get("model");

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [projectMetaData, setProjectMetaData] = useState<IProjectMetaDataDante>({
    projectId: "",
    sessionId: "",
    avatar: "",
    appendPrompt: "",
    botName: "",
    dynamicFirstQuestion: "",
    initial_prompts: []
  });
  const [metadataLoading, setMetadataLoading] = useState(true);
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState({ show: false, error: "" });
  const [streaming, setStreaming] = useState(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined
  });

  const chatBoxRef: any = useRef(null);
  const stopGeneratedRef = useRef({
    enableIcon: false,
    stopText: false
  });

  const addDynamicFirstQuestion = (question: string) => {
    setMessages([
      {
        text: question || defaultQuestion,
        sender: USER_LABEL_VALUE.CHATBOT
      }
    ]);
  };

  const msgJsx = {
    chatbot: (text: string, key: number) => (
      <MsgComponent
        key={key}
        logo={projectMetaData.avatar}
        botName={projectMetaData.botName}
        msg={text}
      />
    ),
    user: (text: string, key: number) => <ReplyComponent key={key} msg={text} />
  };

  const setMetaData = async (projectId: string) => {
    // check project exist or not
    const {
      data: { chatbot_avatar, chatbot_name, first_chatbot_question, append_prompt, initial_prompts }
    } = await checkProjectExist(projectId);
    // const keyForStorage = `session_for_${projectId}`;
    let sessionId = "";
    // await getObject(keyForStorage);
    if (!sessionId) {
      // no session hence create it
      sessionId = await createSessionDante(projectId);
      // setObject(keyForStorage, sessionId);
    }

    setProjectMetaData({
      projectId: projectId,
      sessionId: sessionId,
      avatar: chatbot_avatar,
      appendPrompt: append_prompt,
      botName: chatbot_name,
      dynamicFirstQuestion: first_chatbot_question,
      initial_prompts
    });

    addDynamicFirstQuestion(first_chatbot_question);

    setMetadataLoading(false);
  };

  useEffect(() => {
    if (projectId) {
      setMetaData(projectId);
    }
  }, []);

  useEffect(() => {
    console.log(projectMetaData);
  }, [projectMetaData]);

  useEffect(() => {
    // Scroll down when a new message is added
    if (chatBoxRef !== null && !metadataLoading) {
      chatBoxRef.current.scrollTop = chatBoxRef?.current?.scrollHeight;
    }
  }, [messages, chatBoxRef]);

  const setNewMessageInArr = (newMsg: string) => {
    setMessages((prev) => {
      const index = prev.length - 1;
      prev[index].text = newMsg;
      return [...prev];
    });
  };

  const doRetryForPrompt = (question: string, isRetry: boolean) => {
    setMessages((prevMessage) => {
      return !isRetry
        ? [...prevMessage]
        : [
            ...prevMessage,
            {
              text: question,
              sender: "user"
            }
          ];
    });

    // send isRetry as true because it is retry call
    sendPromptToService(question, true);
  };

  const checkForErrorInMessage = (msg: string, question: string, isRetry: boolean) => {
    if (msg === errorMessagesCode.timeoutErrorCode) {
      setNewMessageInArr(isRetry ? timeoutMessage : retryTimeoutMessage);
      // if it was not retry call then do the retry by sending prompt again
      if (!isRetry) doRetryForPrompt(question, isRetry);
      // return true to stop api call
      return true;
    }
  };

  const sendPromptToService = (question: string, isRetry = false) => {
    fetch(`${BASE_API_URL}/v1/ask-morgan/send-prompt?model=${model ?? 3}`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        "x-api-key": REACT_APP_ASK_MORGAN_API_KEY
      },
      body: JSON.stringify({
        prompt: question,
        project_id: projectMetaData.projectId,
        session_id: projectMetaData.sessionId
      }) // body data type must match "Content-Type" header
    }).then((res: any) => {
      const reader = res.body.getReader();
      setLoading(false);
      setStreaming(true);
      let str = "";
      const read = () => {
        // read the data
        reader.read().then(({ done, value }: any) => {
          const decoder = new TextDecoder();
          if (
            checkForErrorInMessage(decoder.decode(value), question, isRetry) ||
            done ||
            stopGeneratedRef.current.stopText
          ) {
            stopGeneratedRef.current = {
              enableIcon: false,
              stopText: false
            };
            setStreaming(false);
            return;
          }

          str += decoder.decode(value);
          setNewMessageInArr(str);
          read();
        });
      };

      setMessages((prev) => {
        return [...prev, { text: "", sender: "chatbot" }];
      });

      read();
    });
    //   fetch end
  };

  const handleSendMessage = async (suggestedPrompt?: string) => {
    if (!suggestedPrompt && (userInput === "" || userInput === null || userInput === undefined)) {
      setInputError({
        show: true,
        error: "Message cannot be empty"
      });
      return;
    }
    if (streaming) {
      setInputError({
        show: true,
        error:
          "Cannot send message while the bot is answering, Please wait or click 'Stop Generating' and try again"
      });
      return;
    }
    setLoading(true);

    // start : this thing will make sure before sending message stopGeneration is false
    stopGeneratedRef.current = {
      enableIcon: true,
      stopText: false
    };

    // end : this thing will make sure before sending message stopGeneration is false
    const newMessage = suggestedPrompt || userInput;
    setMessages([...messages, { text: newMessage, sender: "user" }]);
    setUserInput("");
    sendPromptToService(newMessage);
    setInputError({
      show: false,
      error: ""
    });
  };

  const clearConversation = () => {
    if (!stopGeneratedRef.current.enableIcon) setMessages([]);
    addDynamicFirstQuestion(projectMetaData.dynamicFirstQuestion);
  };

  const stopGeneration = () => {
    stopGeneratedRef.current = {
      enableIcon: false,
      stopText: true
    };
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
      return;
    }
    return;
  };

  return (
    <div className="chatbot_container">
      {metadataLoading ? (
        <div className="chatbot-preloader">
          <GenericLoader />
        </div>
      ) : (
        <>
          {/* conditional class "border_bottom" */}
          <div className="header-wrapper">
            <div className="chatbot-header">
              <div className="header-col"></div>
              <div className="header-col header_title">{projectMetaData.botName} Ai assistant </div>
              <div className="header-col">
                {/* {!isMobile && <div className="header-language">English</div>} */}
                {isMobile && (
                  <>
                    <IonPopover
                      cssClass="header-language"
                      event={popoverState.event}
                      isOpen={popoverState.showPopover}
                      onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                    >
                      <IonList>
                        <IonItem
                          className="close-button-mobile"
                          lines="none"
                          detail={false}
                          onClick={() => {
                            clearConversation();
                            setShowPopover({ showPopover: false, event: undefined });
                          }}
                        >
                          <div className="close-button-items">
                            <span>Clear all chat</span>
                            <img
                              onClick={() => {
                                return;
                              }}
                              src={getImagePath(`svg/bin_icon.svg`)}
                              alt="send"
                            />
                          </div>
                        </IonItem>
                      </IonList>
                    </IonPopover>
                    <button
                      className="header-button"
                      onClick={(e: any) => {
                        e.persist();
                        setShowPopover({ showPopover: true, event: e });
                      }}
                    >
                      <img
                        onClick={() => {
                          return;
                        }}
                        src={getImagePath(`svg/3dots_circle.svg`)}
                        alt="send"
                      />
                    </button>
                  </>
                )}
              </div>
            </div>
            {isMobile && <div className="header-powered_text">Powered by QuantumLoopAi</div>}
          </div>
          <div ref={chatBoxRef} className="chatbot-content">
            {messages.map((message, index) => {
              return msgJsx[message.sender](message.text, index);
            })}
            {loading ? (
              <div className="msg-container">
                <div className="msg-avatar">
                  <img
                    alt="image"
                    src={projectMetaData.avatar}
                    loading="lazy"
                    className="msg-image"
                  />
                </div>
                <div className="msg-content_wrapper">
                  <div className="company_name">{projectMetaData.botName}</div>
                  <div className="chat_prompt"></div>
                  <div className="msg_loading">
                    <img src={getImagePath(`svg/loading_grey.svg`)} alt="send" />
                  </div>
                </div>
              </div>
            ) : null}
            {streaming && !loading ? (
              <div className="msg-container">
                <div className="msg-avatar"></div>
                <div className="msg-content_wrapper">
                  <div className="company_name"></div>
                  <div className="chat_prompt"></div>
                  <div
                    className={`msg_loading ${streaming && !loading ? "modify-margin-top" : null}`}
                  >
                    <img src={getImagePath(`svg/loading_grey.svg`)} alt="send" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {streaming ? (
            isMobile ? null : (
              <button className="stop-streaming-btn" onClick={stopGeneration}>
                <span className="stop-streaming-btn-icon">
                  <img src={getImagePath(`svg/stop.svg`)} alt="send" />
                </span>
                Stop Generating
              </button>
            )
          ) : null}
          {!streaming ? (
            <PromptSuggestion
              prompts={projectMetaData.initial_prompts}
              handleSendMessageCallback={handleSendMessage}
            />
          ) : null}
          <div className="chatbot-footer">
            <div className="footer-input_container">
              <textarea
                onChange={(e) => {
                  setInputError({
                    show: false,
                    error: ""
                  });
                  setUserInput(e.target.value);
                }}
                value={userInput}
                className="footer-chat_input"
                placeholder={loading ? "Thinking..." : "Ask me anything..."}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
              ></textarea>
              {/* "active" class should be conditional with send button  */}
              {!isMobile ? (
                <button
                  className={`send_btn ${userInput.length ? "active" : null}`}
                  onClick={() => handleSendMessage()}
                  disabled={streaming || !userInput.length}
                >
                  <img src={getImagePath(`svg/send_msg_btn.svg`)} alt="send" />
                </button>
              ) : null}
              {isMobile ? (
                streaming ? (
                  <button className="stop_btn" onClick={stopGeneration}>
                    <img src={getImagePath(`svg/stop-stream.svg`)} alt="send" />
                  </button>
                ) : (
                  <button
                    className={`send_btn ${userInput.length ? "active" : null}`}
                    onClick={() => handleSendMessage()}
                    disabled={streaming || !userInput.length}
                  >
                    <img src={getImagePath(`svg/send_msg_btn.svg`)} alt="send" />
                  </button>
                )
              ) : null}
              {!isMobile &&
                (loading ? null : (
                  <button className="delete_btn" onClick={clearConversation}>
                    <img src={getImagePath(`svg/trash_grey.svg`)} alt="send" />
                  </button>
                ))}
            </div>
            {inputError.show ? (
              <span className="chatbot-textarea-error" style={{ color: "red" }}>
                {inputError.error}
              </span>
            ) : null}
            {!isMobile && <div className="footer-powered_text">Powered by QuantumLoopAi</div>}
          </div>
        </>
      )}
    </div>
  );
};

export default AskMorganChatBotV2;
