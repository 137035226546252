import { CDN_URL_MEDIA } from "@constants/env.constants";

export const getImagePath = (path: string) => {
  // return require(`@assets/${path}`);
  return require(`../../assets/${path}`);
};

/**
 * Helper method to get absolute url of media uploaded to CDN
 * @param relativePath relative path to media (should start with /)
 * @returns absolute url for CDN media
 */
export const getMediaUrl = (relativePath: string) => {
  return `${CDN_URL_MEDIA}${relativePath}`;
};
