import { MARKETING_FEED_STATES } from "@containers/PropertyRightPane/ListingDetailsSection/MarketingFeeds/types";

export const FEED_STATE_TO_DISPLAY_STATUS_MAP = [
  {
    state: "not_added",
    color: "default",
    label: "Not added"
  },
  {
    state: "added",
    color: "success",
    label: "Active"
  },
  {
    state: "removed",
    color: "danger",
    label: "Inactive"
  },
  {
    state: "under_offer",
    color: "primary",
    label: "Under offer"
  },
  {
    state: "let",
    color: "warning",
    label: "Let"
  },
  {
    state: "sold",
    color: "warning",
    label: "Sold STC"
  }
];

export const ALL_MARKETING_FEED_PORTALS = [
  "Rightmove",
  "Zoopla",
  "OnTheMarket",
  "All Other Portals"
];

export const FEED_STATUS_REFETCH_IN_MINS = 5;

export const FEED_CHECKBOX_CHECKED_STATES = [
  MARKETING_FEED_STATES.ADDED,
  MARKETING_FEED_STATES.LET,
  MARKETING_FEED_STATES.UNDER_OFFER,
  MARKETING_FEED_STATES.SOLD
];
