export enum ReactQueriesKeys {
  AllAgents = "AllAgents",
  Applicants = "Applicants",
  OwnerDetails = "OwnerDetails",
  Owners = "Owners",
  OwnersListForDropdown = "OwnersListForDropdown",
  Properties = "Properties",
  propertyCount = "propertyCount",
  SingleProperty = "SingleProperty",
  ListingDetails = "ListingDetails",
  MarketingFeedLinks = "MarketingFeedLinks",
  OverallValidation = "OverallValidation",
  Offers = "Offers",
  SingleOffer = "SingleOffer",
  OfferHistory = "OfferHistory"
}
