export enum PROPERTY_LISTING_TYPE {
  RENT = "rent",
  SALE = "sale"
}

export enum PROPERTY_AGENT_TYPE {
  LISTING = "listing",
  VIEWING = "viewing",
  PROPERTY_MANAGER = "property_manager"
}
