import { PROPERTY_FILTER_KEYS } from "@components/PropertyFilterPopup/PropertyFilterConstants";

export const clearPropertyPaneQueryParams = (searchParams: URLSearchParams) => {
  searchParams.delete("property_search_query");
  searchParams.delete("property_sort_by");
  searchParams.delete("property_sort_order");
  searchParams.delete(PROPERTY_FILTER_KEYS.location);
  searchParams.delete(PROPERTY_FILTER_KEYS.move_in_date);
  searchParams.delete(PROPERTY_FILTER_KEYS.no_min);
  searchParams.delete(PROPERTY_FILTER_KEYS.no_max);
  searchParams.delete(PROPERTY_FILTER_KEYS.bedroom);
  searchParams.delete(PROPERTY_FILTER_KEYS.bathroom);
  searchParams.delete(PROPERTY_FILTER_KEYS.furnishing);
  searchParams.delete(PROPERTY_FILTER_KEYS.parking);
  searchParams.delete(PROPERTY_FILTER_KEYS.pets);
  searchParams.delete(PROPERTY_FILTER_KEYS.propertyType);
};
