/**
 * This file will contain all constants related to the environment variables
 */

import { Capacitor } from "@capacitor/core";

export const ENVIRONMENT = process.env.NODE_ENV;

export const REACT_APP_PLATFORM = process.env.REACT_APP_PLATFORM;

export const REACT_APP_PL_APP_BASE_URL = process.env.REACT_APP_PL_APP_BASE_URL;

export const IS_NATIVE = REACT_APP_PLATFORM !== "web";

export const IS_LOCAL_ENV =
  window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

export const IMAGE_PATH_PREFIX =
  ENVIRONMENT === "development" || REACT_APP_PLATFORM !== "web"
    ? "../../assets/"
    : "/build/assets/";

export const PL_SERVER_BASE_API_URL = process.env.REACT_APP_PL_SERVER_BASE_API_URL;

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const CDN_URL_WEB_BUILD_ASSETS =
  process.env.REACT_APP_CDN_URL_WEB_BUILD_ASSETS + "/build/assets";

export const CDN_URL_MEDIA = process.env.REACT_APP_CDN_URL_MEDIA;

export const CAPACITOR_PLATFORM = Capacitor.getPlatform();

export const REACT_APP_SENDBIRD_APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID;

export const REACT_APP_BOOK_MEDIA_BASE_URL = process.env.REACT_APP_BOOK_MEDIA_BASE_URL;

export const REACT_APP_IMPERSONATE_REQUEST_SLACK_LINK =
  process.env.REACT_APP_IMPERSONATE_REQUEST_SLACK_LINK;

export const REACT_APP_ASK_MORGAN_API_KEY = process.env.REACT_APP_ASK_MORGAN_API_KEY || "test";

export const HOME_PAGE_URL = process.env.REACT_APP_HOME_PAGE_URL;

export const HELP_PAGE_URL = process.env.REACT_APP_HELP_PAGE_URL;

export const MARKETING_PAGE_URL = process.env.REACT_APP_MARKETING_PAGE_URL;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

export const REACT_APP_FEATURE_FLAG = process.env.REACT_APP_FEATURE_FLAG || "0";

export const IS_FEATURE_FLAG_ENABLED = process.env.REACT_APP_FEATURE_FLAG === "1";

export const REACT_APP_HUBSPOT_BASE_URL = process.env.REACT_APP_HUBSPOT_BASE_URL || "";

export const REACT_APP_HUBSPOT_CUSTOM_OBJECT_PROPERTY_OBJECT_TYPE_ID =
  process.env.REACT_APP_HUBSPOT_CUSTOM_OBJECT_PROPERTY_OBJECT_TYPE_ID || "";

export const REACT_APP_HUBSPOT_CUSTOM_OBJECT_ENQUIRY_OBJECT_TYPE_ID =
  process.env.REACT_APP_HUBSPOT_CUSTOM_OBJECT_ENQUIRY_OBJECT_TYPE_ID || "";

export const REACT_APP_HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID || "";

export const REACT_APP_HUBSPOT_CONTACT_OBJECT_TYPE_ID =
  process.env.REACT_APP_HUBSPOT_CONTACT_OBJECT_TYPE_ID || "";
