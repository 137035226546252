import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { PERSIST, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

import authReducer from "@store/auth/authSlice";
import ddlReducer from "@store/ddl/ddlSlice";
import uploadMediaReducer from "@store/properties/uploadMediaSlice";
import propertyRightPaneReducer from "@store/properties/propertyRightPaneSlice";
import messagesReducer from "@store/messages/messagesSlice";
import reportBugReducer from "@store/reportBug/reportBugSlice";
import salePropertiesReducer from "@store/properties/salePropertiesSlice";
import applicantEnquirySlice from "@store/applicant-enquiry/applicantEnquirySlice";
import { IGNORED_MESSAGE_ACTION, IGNORED_MESSAGE_PATH } from "@constants/messages.constant";

const reducers = combineReducers({
  auth: authReducer,
  ddl: ddlReducer,
  uploadMedia: uploadMediaReducer,
  messages: messagesReducer,
  propertyRightPane: propertyRightPaneReducer,
  reportBug: reportBugReducer,
  saleProperties: salePropertiesReducer,
  applicantEnquiry: applicantEnquirySlice
});

const persistConfig = {
  key: "ddl",
  storage: storageSession,
  whitelist: ["ddl"],
  serialize: true,
  writeFailHandler: () => console.log("error while saving in storage")
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, IGNORED_MESSAGE_ACTION],
        ignoredPaths: [IGNORED_MESSAGE_PATH]
      }
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
