import { ENVIRONMENT } from "@constants/env.constants";
import { IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./ReportToSlack.scss";

interface ErrorProps {
  error?: string | null;
  showBackBtn?: boolean;
}

const ReportToSlack = ({ error, showBackBtn = false }: ErrorProps) => {
  const navigate = useNavigate();
  // function
  function goBack() {
    navigate(-1);
  }

  return (
    <IonGrid className="error-wrapper">
      <IonRow className="w-100 justify-content-center danger_text">
        The system has encountered an error, please try refreshing the page or&nbsp;
        <a
          className="report_link"
          target="_blank"
          href="https://propertyloop.slack.com/archives/CNS9J3XR6"
        >
          report a bug
        </a>
        &nbsp;if the issue continues.
      </IonRow>
      <IonRow>
        <IonGrid className="error-details">{ENVIRONMENT !== "production" && error}</IonGrid>
        <br />
        {showBackBtn && (
          <IonGrid className="redirect-btn" onClick={goBack}>
            Back
          </IonGrid>
        )}
      </IonRow>
    </IonGrid>
  );
};

export default ReportToSlack;
