import { Storage } from "@capacitor/storage";

export const setObject = async (key: string, value: string) => {
  await Storage.set({
    key: key,
    value: value
  });
};

export const clearStorage = async () => {
  await Storage.clear();
};

export const getObject = async (key: string) => {
  const ret = await Storage.get({ key: key });
  if (ret) {
    return ret.value;
  }
  return null;
};

export const storeTokenInLocalStorage = async ({ token = "", expirationTime = "" }) => {
  await setObject("tid", token.toString());
  await setObject("txp", expirationTime);
};
