export enum SALE_PROPERTIES_RIGHT_PANE_ACCORDION_ENUM {
  NO_SECTION = "",
  ASSIGNED_AGENT = "saleAssignedAgent",
  ASSIGNED_VIEWING_AGENT = "saleAssignedViewingAgent",
  NOTES = "saleNotes",
  VIEWING_URL = "saleViewingUrl",
  MARKETING_FEEDS = "saleMarketingFeeds",
  PROPERTY_DETAILS = "propertyDetails",
  MARKETING_DETAILS = "saleMarketingDetails",
  EPC_GSC = "saleEpcGsc",
  LISTING_DESCRIPTION = "saleListingDescription",
  GETTING_AROUND = "saleGettingAround",
  LOCAL_LIFE = "saleLocalLife",
  ROOMS = "saleRooms",
  COUNCIL_TAX = "saleCouncilTax",
  STREET_VIEW = "saleStreetView",
  PROPERTY_TYPE = "salePropertyType",
  MAIN_BULLET_POINTS = "saleMainBulletPoints",
  UPLOAD_MEDIA = "saleMedia",
  FEATURES = "saleFeatures"
}

export enum MARKETING_DETAILS_SECTION {
  AREA_SQ_MTR = "area_sq_mtr",
  TENURE = "tenure",
  PARKING = "parking",
  LISTING_PRICE = "listing_price"
}
