import { getImagePath } from "@utils/helpers/media.helper";
import "./ReplyComponentQlAi.scss";
import React from "react";

interface IReplyComponentProps {
  msg: string;
}

const ReplyComponentQlAi: React.FC<IReplyComponentProps> = ({ msg }) => {
  return (
    <div className="msg-container">
      <div className="msg-avatar_reply">
        <img src={getImagePath(`svg/user_grey.svg`)} alt="send" />
      </div>
      <div className="msg-content_wrapper">
        <div className="company_name">You</div>
        <div className="chat_prompt">{msg}</div>
      </div>
    </div>
  );
};

export default ReplyComponentQlAi;
