// imports
import { ISingleProperty } from "@constants";
import { createSlice } from "@reduxjs/toolkit";

// interfaces
interface ISalePropertiesSlice {
  selectedProperty: ISingleProperty | null;
  isSingleEditableAccordionMounted: boolean;
}

// variable declaration
const initialState: ISalePropertiesSlice = {
  selectedProperty: null,
  isSingleEditableAccordionMounted: false
};

// saleProperties slice
export const salePropertiesSlice = createSlice({
  name: "saleProperties",
  initialState,
  reducers: {
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload;
    },
    setIsSaleSingleEditableAccordionMounted: (state, action) => {
      state.isSingleEditableAccordionMounted = action.payload;
    }
  }
});

export const { setSelectedProperty, setIsSaleSingleEditableAccordionMounted } =
  salePropertiesSlice.actions;
export default salePropertiesSlice.reducer;
