// imports
import { createSlice } from "@reduxjs/toolkit";

// interfaces
interface IReportBugSlice {
  isPopupOpen: boolean;
}

// variable declaration
const initialState: IReportBugSlice = {
  isPopupOpen: false
};

// reportBug slice
export const reportBugSlice = createSlice({
  name: "reportBug",
  initialState,
  reducers: {
    toggleReportBugPopup: (state, action) => {
      state.isPopupOpen = action.payload;
    }
  }
});

export const { toggleReportBugPopup } = reportBugSlice.actions;
export default reportBugSlice.reducer;
