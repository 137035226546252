import { AnyAction, Dispatch, ThunkDispatch } from "@reduxjs/toolkit";
import { AuthState, logout, setLoading, setUser } from "@store/auth/authSlice";
import apiService from "@utils/apiService";
import { getObject } from "@utils/localStorage";
import axios from "axios";

export const setAuthUser = async (
  dispatch: ThunkDispatch<
    {
      auth: AuthState;
    },
    undefined,
    AnyAction
  > &
    Dispatch<AnyAction>
) => {
  const token = await getObject("tid");
  if (!token) return;

  dispatch(setLoading());
  axios.defaults.headers.common["authorization"] = `bearer ${token as string}`;
  try {
    const { data: user } = await apiService.get("/v1/agent");
    if (!user) throw new Error("No details found");
    dispatch(setUser(user));
  } catch (error) {
    console.error(error);
    dispatch(logout());
  }
};

export const setAuthUserWithoutLoading = async (
  dispatch: ThunkDispatch<
    {
      auth: AuthState;
    },
    undefined,
    AnyAction
  > &
    Dispatch<AnyAction>
) => {
  const token = await getObject("tid");
  if (!token) return;

  axios.defaults.headers.common["authorization"] = `bearer ${token as string}`;
  try {
    const { data: user } = await apiService.get("/v1/agent");
    if (!user) throw new Error("No details found");
    dispatch(setUser(user));
  } catch (error) {
    console.error(error);
    dispatch(logout());
  }
};
