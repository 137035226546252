import { createSlice } from "@reduxjs/toolkit";
import apiService from "@utils/apiService";
import { DDLList } from "@constants/owners";

// interface for store state
export interface DDLState {
  ddlList: DDLList;
}

interface DDLListRequest {
  [ddlCodes: string]: string[];
}

export const fetchDDLs = (ddlList: DDLListRequest) => async (dispatch: any) => {
  try {
    const allDDLResponse = await apiService.post(`/v1/dropdown`, ddlList);
    dispatch(setDDLList(allDDLResponse.data));
  } catch (error) {
    console.error(error);
  }
};

// initial state
const initialState: DDLState = {
  ddlList: {}
};

// DDLSlice
export const DDLSlice = createSlice({
  name: "ddl",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDDLList: (state: DDLState, { payload }: { payload: DDLList }) => {
      state.ddlList = payload;
    }
  }
});

// export actions from slice if any reducers created
// export const { setDdl } = DDLSlice.actions;
export const { setDDLList } = DDLSlice.actions;

export default DDLSlice.reducer;
