import { PROPERTY_LISTING_TYPE } from "@constants/enum";
import { MEDIA_TYPES } from "@constants/properties";
import { VIMEO_MY_VIDEOS_API_URL } from "@constants/vimeo";
import { UploadSetterMediaType } from "@containers/PropertyRightPane/ListingDetailsSection/Media/types";
import useUploadFile from "@hooks/useUploadFile";
import { createSlice } from "@reduxjs/toolkit";
import { getVideoId, createVideoInVimeo, deleteVideoFromVimeo } from "@utils/vimeo";
import * as tus from "tus-js-client";
interface SetUploadProgressInterface {
  type: UploadSetterMediaType;
  data: boolean;
}
// initial state
const initialState: any = {
  isUploadInProgress: {
    [MEDIA_TYPES.PHOTOS]: false,
    [MEDIA_TYPES.VIDEOS]: false,
    [MEDIA_TYPES.FLOOR_PLANS]: false
  },
  uploadBatches: {
    [MEDIA_TYPES.PHOTOS]: {},
    [MEDIA_TYPES.VIDEOS]: {},
    [MEDIA_TYPES.FLOOR_PLANS]: {}
  },
  uploadResponse: {
    [MEDIA_TYPES.PHOTOS]: {},
    [MEDIA_TYPES.VIDEOS]: {},
    [MEDIA_TYPES.FLOOR_PLANS]: {}
  }
};

const updateBatchPercentage = (
  dispatch: any,
  progressEvent: any,
  batchNo: string,
  mediaType: UploadSetterMediaType
) => {
  const { loaded, total } = progressEvent;

  const percentage = Math.floor((loaded * 100) / total);

  if (percentage > 98) return;
  dispatch(
    setUploadMediaBatches({
      type: mediaType,
      data: { [batchNo]: percentage }
    })
  );

  dispatch(
    setUploadMediaList({
      type: mediaType,
      data: true
    })
  );
};

export const uploadImage =
  (
    propertyId: string,
    formData: FormData,
    batchNo: string,
    mediaType: UploadSetterMediaType,
    listing_type: PROPERTY_LISTING_TYPE
  ) =>
  async (dispatch: any) => {
    try {
      useUploadFile(
        `/v1/properties/${propertyId}/listing-details/media/azure-storage?property_listing_type=${listing_type}`,
        formData,
        (progressEvent: any) => {
          const { loaded, total } = progressEvent;

          if (loaded === total) {
            // temp fix to show file upload percentage
            updateBatchPercentage(dispatch, { total: 100, loaded: 25 }, batchNo, mediaType);
          } else {
            updateBatchPercentage(dispatch, progressEvent, batchNo, mediaType);
          }
        }
      )
        .then(({ data: response }) => {
          updateBatchPercentage(dispatch, { total: 100, loaded: 100 }, batchNo, mediaType);
          dispatch(
            setUploadResponse({
              type: mediaType,
              data: { batchNo, mediaUrls: response.mediaUrls }
            })
          );
        })
        .catch((err) => {
          dispatch(
            setUploadMediaBatches({
              type: mediaType,
              data: { [batchNo]: -1 }
            })
          );
          console.error("Upload Manage Listing Media Error", err);
        });
    } catch (error) {
      console.error(error);
    }
  };

// upload to vimeo
export const uploadVideoToVimeo =
  (
    propertyId: string,
    formData: FormData,
    batch: string,
    file: any,
    mediaType: UploadSetterMediaType,
    propertyShortAddress: string
  ) =>
  async (dispatch: any) => {
    try {
      const fileName = `${propertyShortAddress || propertyId} (Admin Video)`;
      const fileSize = file.size.toString();
      updateBatchPercentage(dispatch, { total: fileSize, loaded: 0 }, batch, mediaType);
      const response = await createVideoInVimeo(fileName, fileSize);
      const upload = new tus.Upload(file, {
        endpoint: VIMEO_MY_VIDEOS_API_URL,
        uploadUrl: response.upload.upload_link,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: fileName,
          filetype: file.type
        },
        headers: {},
        onError: async function (error) {
          dispatch(
            setUploadMediaBatches({
              type: mediaType,
              data: { [batch]: -1 }
            })
          );
          console.log("Vimeo Upload Failed" + error);
          const videoId = getVideoId(response.uri);
          if (videoId) await deleteVideoFromVimeo(videoId);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          updateBatchPercentage(
            dispatch,
            { total: bytesTotal, loaded: bytesUploaded },
            batch,
            mediaType
          );
        },
        onSuccess: function () {
          updateBatchPercentage(dispatch, { total: 100, loaded: 100 }, batch, mediaType);
          dispatch(
            setUploadResponse({
              type: mediaType,
              data: { batch, mediaUrls: response.link }
            })
          );
        }
      });
      upload.start();
    } catch (error) {
      console.error("Upload Manage Listing Video Upload Error", error);
    }
  };

// UploadMediaSlice
export const UploadMediaSlice = createSlice({
  name: "upload_media",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUploadMediaList: (state: any, action) => {
      const { type, data }: SetUploadProgressInterface = action.payload;

      const isUploadInProgress = state.isUploadInProgress[MEDIA_TYPES.PHOTOS];
      if (isUploadInProgress !== data) {
        state.isUploadInProgress = {
          ...state.isUploadInProgress,
          [type]: data
        };
      }
    },
    setUploadMediaBatches: (state: any, action) => {
      const { type, data }: { type: UploadSetterMediaType; data: any } = action.payload;

      state.uploadBatches = {
        ...state.uploadBatches,
        [type]: { ...state.uploadBatches[type], ...data }
      };
    },
    deleteCompletedMediaBatches: (state: any, action) => {
      const { type, data }: { type: UploadSetterMediaType; data: string } = action.payload;
      const batches = { ...state.uploadBatches[type] };
      delete batches[data];

      state.uploadResponse = {
        ...state.uploadResponse,
        [type]: {}
      };
      if (Object.keys(batches).length === 0) {
        state.isUploadInProgress = {
          ...state.isUploadInProgress,
          [type]: false
        };
      }
      state.uploadBatches = {
        ...state.uploadBatches,
        [type]: { ...batches }
      };
    },
    setUploadResponse: (state: any, action) => {
      const { type, data } = action.payload;

      state.uploadResponse = {
        ...state.uploadResponse,
        [type]: { ...data }
      };
    }
  }
});

export const {
  setUploadMediaList,
  setUploadMediaBatches,
  deleteCompletedMediaBatches,
  setUploadResponse
} = UploadMediaSlice.actions;

export default UploadMediaSlice.reducer;
