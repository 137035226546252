import React, { useEffect, useState } from "react";
import Login from "./Login";
import Joi from "joi";
import { useAppDispatch, useAppSelector } from "@hooks";
import { firebaseLogin, isLoggedInFromStore, loginErrorFromStore } from "@store/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { MICROSOFT_PROVIDER, PASSWORD_PROVIDER } from "@constants/firebase_providers.constants";

interface LocationProps {
  state: {
    from: {
      pathname: string;
      search: string;
    };
  };
}

const LoginContainer = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    error: {}
  });
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(isLoggedInFromStore);
  const loginError = useAppSelector(loginErrorFromStore);
  const navigate = useNavigate();
  const location = useLocation() as LocationProps;

  useEffect(() => {
    if (isLoggedIn) {
      if (location?.state?.from) {
        const { pathname, search } = location.state.from;
        navigate(`${pathname}${search || ""}`, { replace: true });
      } else navigate(`/`);
    }
  }, [isLoggedIn, location, navigate]);

  const onChange = (field: string, value: string | undefined | null) => {
    setFormData({ ...formData, [field]: value });
  };

  const loginWithEmailAndPassword = () => {
    const schema = Joi.object({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          "string.email": "Please enter a valid email",
          "string.empty": "Email is required"
        }),
      password: Joi.string().required().messages({
        "string.empty": "Password is required"
      })
    }).unknown();
    const {
      value: { email, password },
      error
    } = schema.validate(formData);
    if (error) {
      return setFormData({
        ...formData,
        error: { [error.details[0].path[0]]: error.message }
      });
    }
    dispatch(firebaseLogin({ provider: PASSWORD_PROVIDER, payload: { email, password } }));
    setFormData({
      email: "",
      password: "",
      error: {}
    });
  };

  const microsoftSSO = () => {
    dispatch(firebaseLogin({ provider: MICROSOFT_PROVIDER }));
  };

  const handleFirebaseLogin = (method: string) => {
    switch (method) {
      case PASSWORD_PROVIDER:
        loginWithEmailAndPassword();
        break;
      case MICROSOFT_PROVIDER:
        microsoftSSO();
        break;
      default:
        console.error("Login method not handled");
        break;
    }
  };

  return (
    <Login
      formData={formData}
      onChange={onChange}
      handleFirebaseLogin={handleFirebaseLogin}
      loginError={loginError}
    />
  );
};

export default LoginContainer;
