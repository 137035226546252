import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { isMobileOnly } from "react-device-detect";
import apiService from "@utils/apiService/apiService";

export interface IBugDetails {
  title: string;
  issue: string;
  description: string;
  url: string;
  sessionUrl?: string;
}

interface ISystemInfo {
  device: string;
  browser: string;
  os: string;
  osVersion: string;
  dimensions: {
    height: number;
    width: number;
  };
}

interface IUserDetails {
  uid: string;
  email: string;
  displayName: string;
}

interface IReportBugBody {
  systemInfo: ISystemInfo;
  userDetails: IUserDetails;
  bugDetails: IBugDetails;
}

const reportBug = (reportBugBody: IReportBugBody) => {
  return apiService.post(`/v1/bug/report`, { ...reportBugBody });
};

const useReportBug = () => {
  const BUG_REPORTED_SUCCESS_MSG = "Bug successfully reported";

  return useMutation(reportBug, {
    onSuccess: () => {
      toast.success(BUG_REPORTED_SUCCESS_MSG, {
        position: isMobileOnly ? "bottom-center" : "top-right",
        duration: 4000,
        className: "toast"
      });
    },
    onError: () => {
      console.error("Error while reporting bug");
    }
  });
};

export default useReportBug;
