import axios from "axios";
import LogRocket from "logrocket";
import { IS_LOCAL_ENV } from "@constants/env.constants";

export const setupLogRocket = () => {
  try {
    if (process.env.REACT_APP_ENABLE_LOGROCKET === "1" && !IS_LOCAL_ENV)
      LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID || "");
  } catch (error) {
    console.error(`Error in LogRocket init. Error: ${error}`);
  }
};

export const GetLogRocketSessionURL = () => {
  return new Promise((resolve, reject) => {
    try {
      LogRocket.getSessionURL((url) => {
        resolve(url);
      });
    } catch (err) {
      reject(err);
    }
  });
};

// this function will wait for only provided mili seconds for logrocket session url , if not generated will throw error
export const getLogRocketSessionURLWithTimeout = async (timeInMs: number) => {
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Request timed out")), timeInMs)
  );

  try {
    const logRocketSessionUrl = await Promise.race([GetLogRocketSessionURL(), timeout]);
    return logRocketSessionUrl;
  } catch (error) {
    return null;
  }
};

export const setLogRocketHeader = async () => {
  if (process.env.REACT_APP_ENABLE_LOGROCKET === "1" && !IS_LOCAL_ENV) {
    if (!axios.defaults.headers.common["logrocket-id"]) {
      try {
        const sessionURL = (await GetLogRocketSessionURL()) as string;
        axios.defaults.headers.common["logrocket-id"] = sessionURL;
      } catch (err) {
        console.error(err);
      }
    }
  }
};

export const setLogRocketUser = (user: any) => {
  try {
    console.log(
      `setting LogRocket user: user.email: ${user?.email}, user.uid: ${user.uid} ${
        user?.phoneNumber ? `, phoneNumber: ${user?.phoneNumber}` : ""
      } `
    );
    LogRocket.identify(user.uid, {
      email: user?.email,
      phoneNumber: user?.phoneNumber
    });
  } catch (error) {
    console.error(`Error setting LogRocket.identify()) Error: ${error} User: ${user}`);
  }
};
