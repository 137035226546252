export const IGNORED_MESSAGE_ACTION = "messages/setSbInstance";
export const IGNORED_MESSAGE_PATH = "messages.sbInstance";

export const EVENT_TYPES = {
  OFFER_SENT: "Offer sent",
  OFFER_RECEIVED: "Offer received",
  OFFER_RECEIVED_FROM_APPLICANT: "Offer received from applicants",
  HOLDING_DEPOSIT_PAID: "Holding deposit paid",
  COUNTER_OFFER_CREATED: "Counter offer created",
  OFFER_ACCEPTED: "Offer accepted",
  OFFER_PLACED: "Offer placed",
  OFFER_REJECTED: "Offer rejected",
  OFFER_CANCELLED: "Offer cancelled",
  OFFER_COUNTERED: "Offer countered",
  OFFER_EXPIRED: "Offer expired",
  PROPERTY_UNDER_OFFER: "Property Under Offer",
  USER_BLOCKED: "has been blocked",
  USER_CALLED: "Call to "
};

export const NO_MESSAGES_TITLE = "You don't have any messages yet";
export const NO_MESSAGES_DESCRIPTION = `Once you get a message, you can reply here. Communicating directly through PropertyLoop
            ensures you're protected against spam, phishing and fraud.`;

export const PILL_TYPES = {
  OFFER: "offer",
  ACCEPTED: "accepted",
  NOT_ACCEPTED: "Not accepted",
  ENQUIRY: "enquiry"
};

export const FILTER_TYPES = [
  {
    id: 1,
    title: "All messages",
    icon: "filter-all-messages"
  },
  {
    id: 2,
    title: "Enquiry",
    icon: "filter-enquiry"
  },
  {
    id: 3,
    title: "Offers",
    icon: "filter-offers"
  },
  {
    id: 4,
    title: "Accepted",
    icon: "filter-accepted"
  },
  {
    id: 5,
    title: "Rejected",
    icon: "filter-rejected"
  },
  {
    id: 6,
    title: "Cancelled",
    icon: "filter-cancelled"
  },
  {
    id: 7,
    title: "Current tenancy",
    icon: "filter-current-tenancy"
  },
  {
    id: 8,
    title: "Past tenancy",
    icon: "filter-past-tenancy"
  },
  {
    id: 9,
    title: "Archived",
    icon: "filter-archived"
  }
];

export const CALLING_ICONS = ["svg/video-off.svg", "svg/microphone.svg", "svg/speaker.svg"];
export const CARD_TYPES = ["INCOMING_CALL", "OUTGOING_CALL"];
export const INCOMING_CALL_CARD_SECONDARY_TEXT_1 = "is calling you";
export const INCOMING_CALL_CARD_SECONDARY_TEXT_2 = "Calling...";

export const HEADER_EVENTS = {
  SEARCH: "Search",
  VIDEO: "Video",
  CALL: "Call",
  INFO: "Info",
  MUTE: "Mute",
  UNMUTE: "Unmute",
  REPORT: "Report",
  BLOCK: "Block",
  UNBLOCK: "Un-Block"
};

export const EMOJI_PICKER_CATEGORIES = [
  "frequent",
  "people",
  "nature",
  "foods",
  "activity",
  "places",
  "objects"
];

export interface BaseMessageDataChild {
  text: string;
  bold?: boolean;
  underline?: boolean;
  type?: string;
  href?: string;
}

export interface BaseMessageData {
  children: BaseMessageDataChild[];
  type?: string;
  href?: string;
}
