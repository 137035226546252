// package imports
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// local imports
import apiService from "@utils/apiService";
import { clearStorage, storeTokenInLocalStorage } from "@utils/localStorage";
import {
  FIREBASE_PROVIDERS,
  MICROSOFT_PROVIDER,
  PASSWORD_PROVIDER
} from "@constants/firebase_providers.constants";

// env import
const { REACT_APP_AD_FIREBASE_TENANT_ID } = process.env;

export interface PasswordProviderPayload {
  email: string;
  password: string;
}

export type FIREBASE_LOGIN_PAYLOAD = PasswordProviderPayload;

const signup = (username: string, email: string, password: string) => {
  return apiService.post(`/signup`, {
    username,
    email,
    password
  });
};

const loginWithPassword = async (email: string, password: string) => {
  const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
  return userCredential;
};

const loginWithMicrosoft = async () => {
  const provider = new firebase.auth.OAuthProvider(MICROSOFT_PROVIDER);
  provider.setCustomParameters({
    tenant: REACT_APP_AD_FIREBASE_TENANT_ID || ""
  });
  const userCredential = await firebase.auth().signInWithPopup(provider);
  return userCredential;
};

const firebaseLogin = async (provider: FIREBASE_PROVIDERS, payload?: PasswordProviderPayload) => {
  let userCredential: any;
  switch (provider) {
    case PASSWORD_PROVIDER:
      payload
        ? (userCredential = await loginWithPassword(payload.email, payload.password))
        : new Error("Credentials not provided");
      break;
    case MICROSOFT_PROVIDER:
      userCredential = await loginWithMicrosoft();
      break;
    default:
      console.error("Firebase login method not handled");
      throw new Error("Firebase login method not handled");
  }
  const idTokenResult = await userCredential?.user?.getIdTokenResult();
  await storeTokenInLocalStorage(idTokenResult as firebase.auth.IdTokenResult);
  apiService.setHeader("authorization", `bearer ${idTokenResult?.token}`);
  const { data: user } = await apiService.get("/v1/agent");
  return user;
};

const logout = async () => {
  await firebase.auth().signOut();
  await clearStorage();
};

export const authAPI = { signup, logout, firebaseLogin };
