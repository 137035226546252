export const debounce = (fn: (args: any) => any, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn.apply(null, [args]);
    }, ms);
  };
};

// implement throttle function
export const throttle = (fn: (args: any) => any, ms = 500) => {
  let flag = true;

  return function (...args: any[]) {
    if (flag) {
      fn.apply(null, [args[0]]);
      flag = false;
      setTimeout(() => {
        flag = true;
      }, ms);
    }
  };
};
