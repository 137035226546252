import { REACT_APP_ASK_MORGAN_API_KEY } from "@constants/env.constants";
import apiService from "@utils/apiService";
import { customUuid } from "@utils/messages";
import { AxiosResponse } from "axios";

interface ISessionResponse {
  sessionId: string;
  message: string;
}

interface ISessionResponseDante {
  data: {
    session_id: string;
    message: string;
  };
}

export interface IProjectMetaDataDante {
  projectId: string;
  sessionId: string;
  avatar: string;
  appendPrompt?: string;
  initial_prompts: string[];
  botName: string;
  dynamicFirstQuestion: string;
}

export interface IProjectMetaData {
  projectId: string;
  sessionId: string;
  avatar: string;
  initial_prompts: string[];
  botName: string;
  dynamicFirstQuestion: string;
}

interface ProjectSuggestedPrompts {
  prompt: string;
  id: string;
}

interface ProjectSettingsResponse {
  statusCode: number;
  data: {
    name: string;
    llm_model_id: string;
    settings: {
      avatar?: string;
      background_img?: string;
      base_prompt?: string;
      initial_prompt?: string;
      language: string;
      placeholder?: string;
      created_by_user: {
        first_name: string;
      };
      updated_by_user: {
        first_name: string;
      };
    };
    suggestedPrompts: ProjectSuggestedPrompts[];
  };
}

interface ProjectResponse {
  data: {
    append_prompt: string;
    chatbot_avatar: string;
    chatbot_name: string;
    first_chatbot_question: string;
    initial_prompts: string[];
  };
  message: string;
  status: number;
}

export interface IMessage {
  text: string;
  sender: "user" | "chatbot";
  citations?: string[];
}

const createSessionApiLink = "/v1/quantumloop-api/session";

export const createSession = async (projectId: string) => {
  const sessionResponse: AxiosResponse<ISessionResponse> = await apiService.post(
    createSessionApiLink,
    {
      project_id: projectId,
      name: customUuid()
    },
    {
      headers: {
        "x-api-key": REACT_APP_ASK_MORGAN_API_KEY
      }
    }
  );
  const { sessionId } = sessionResponse.data;
  return sessionId;
};

const createSessionApiLinkDante = "/v1/ask-morgan/create-session";
export const createSessionDante = async (projectId: string) => {
  const sessionResponse: AxiosResponse<ISessionResponseDante> = await apiService.post(
    createSessionApiLinkDante,
    {
      project_id: projectId,
      name: customUuid()
    },
    {
      headers: {
        "x-api-key": REACT_APP_ASK_MORGAN_API_KEY
      }
    }
  );
  const { session_id } = sessionResponse.data.data;
  return session_id;
};

export const getProjectSettings = async (projectId: string): Promise<ProjectSettingsResponse> => {
  const response = await apiService
    .get(`/v1/quantumloop-api/project-setting/${projectId}`)
    .catch((err) => console.error(err));

  return response?.data;
};

export const checkProjectExist = async (projectId: string): Promise<ProjectResponse> => {
  const response = await apiService.post(
    "/v1/ask-morgan/create-project",
    {
      project_id: projectId,
      name: customUuid()
    },
    {
      headers: {
        "x-api-key": REACT_APP_ASK_MORGAN_API_KEY
      }
    }
  );

  return response.data;
};
