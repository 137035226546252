// package imports
import * as React from "react";
import { IonContent, IonRow, IonText, IonGrid, IonCol, IonPopover } from "@ionic/react";

// local imports
import { Button } from "@components/Button/Button";
import "./Popup.scss";
import { getImagePath } from "@utils/helpers/media.helper";
import { BUTTON_COLOR_TYPES } from "@constants/enum/button";

export interface PopupProps {
  isOpen: boolean;
  testid?: string;
  classes?: string[];
  onClose?: () => void;
  onDismiss?: () => void;
  title?: string;
  caption: string | JSX.Element;
  showCloseIcon: boolean;
  isLoading?: boolean;
  closeDisabled?: boolean;
  textColor?: string;
  verticalScroll?: boolean;
  actions?: {
    label: string | JSX.Element;
    onClick: () => void;
    color?: BUTTON_COLOR_TYPES;
    className?: string;
    style?: React.CSSProperties | object;
    disabled?: boolean;
    testid?: string;
    isLoading?: boolean;
  }[];
  onWillPresent?: () => void;
}

class Popup extends React.Component<PopupProps> {
  render() {
    const {
      title,
      showCloseIcon = true,
      actions,
      caption,
      isOpen,
      onClose,
      onDismiss,
      testid,
      closeDisabled,
      classes = [],
      textColor,
      verticalScroll = true,
      onWillPresent
    } = this.props;

    const slicedActions = actions && actions?.length > 3 ? actions.slice(0, 3) : actions;
    return (
      <IonPopover
        data-testid={testid}
        isOpen={isOpen}
        cssClass={["common-popup", "popup"].concat(classes)}
        onDidDismiss={closeDisabled ? () => null : onDismiss}
        backdropDismiss={!closeDisabled} //if closeDismiss is true, backdropDismiss won't work
        onWillPresent={onWillPresent}
      >
        <IonContent className={`popup--content  ${verticalScroll ? "vScroll" : ""}  `}>
          <IonGrid className="popup--grid">
            <IonRow className="popup--heading-container">
              <IonRow className="ion-justify-content-center ion-no-margin ion-no-padding popup-title">
                <IonCol className="popup--title pl-heading heading-1 ion-no-margin ion-no-padding">
                  <IonText>{title ? title : ""}</IonText>
                </IonCol>
              </IonRow>
              {showCloseIcon && (
                <IonRow className="popup--closeDiv ion-justify-content-end">
                  <IonCol className="popup--closeIconCol">
                    <img
                      data-testid="test-closeIcon"
                      className="popup--closeIcon"
                      src={getImagePath(`svg/cross_icon.svg`)}
                      onClick={closeDisabled ? () => null : onClose}
                    />
                  </IonCol>
                </IonRow>
              )}
            </IonRow>
            <IonRow className="ion-justify-content-center ion-no-margin ion-no-padding popup--caption-container">
              <IonCol className="popup--subTitle ion-no-margin ion-no-padding">
                <IonText style={{ color: textColor || "black" }}>{caption}</IonText>
              </IonCol>
            </IonRow>
            {slicedActions && slicedActions?.length > 0 && (
              <IonRow class="popup--buttonGroup">
                {slicedActions.map((button, index) => (
                  <Button
                    key={index}
                    label={button.label}
                    color={button.color}
                    classes={[`${button.className}`, "popup--btn"]}
                    onClick={button.onClick}
                    styleProps={button.style}
                    disabled={button.disabled}
                    isLoading={button.isLoading}
                  />
                ))}
              </IonRow>
            )}
          </IonGrid>
        </IonContent>
      </IonPopover>
    );
  }
}

export default Popup;
