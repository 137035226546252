import { getImagePath } from "@utils/helpers/media.helper";

export interface MenuSubOption {
  id: number;
  navItemName: string;
  current: boolean;
  isAvailable: boolean;
  isVisibleToAdminOnly: boolean;
  path?: string;
}

export interface MenuOption {
  id: number;
  navItemName: string;
  imgSrc: string;
  imgAlt: string;
  current: boolean;
  isAvailable: boolean;
  isVisibleToAdminOnly: boolean;
  isExpandable: boolean;
  isCollapsed?: boolean;
  path?: string;
  subOptions?: MenuSubOption[];
}

const lettingsSubOptions: MenuSubOption[] = [
  {
    id: 6,
    path: "/properties",
    current: false,
    navItemName: "Properties",
    isVisibleToAdminOnly: false,
    isAvailable: true
  },
  {
    id: 7,
    path: "/users",
    current: false,
    navItemName: "Contacts",
    isVisibleToAdminOnly: true,
    isAvailable: true
  },
  {
    id: 8,
    path: "/offers",
    current: false,
    navItemName: "Offers",
    isVisibleToAdminOnly: false,
    isAvailable: true
  },
  {
    id: 9,
    path: "/deals",
    current: false,
    navItemName: "Deals",
    isVisibleToAdminOnly: false,
    isAvailable: true
  }
];

export const menuOptions: MenuOption[] = [
  {
    id: 1,
    imgSrc: getImagePath(`svg/home.svg`),
    imgAlt: "Home",
    path: "/",
    current: false,
    navItemName: "Home",
    isAvailable: true,
    isVisibleToAdminOnly: false,
    isExpandable: false
  },
  {
    id: 2,
    imgSrc: getImagePath(`svg/tasks.svg`),
    imgAlt: "Tasks",
    path: "/tasks",
    current: false,
    navItemName: "Tasks",
    isVisibleToAdminOnly: false,
    isAvailable: true,
    isExpandable: false
  },
  {
    id: 3,
    imgSrc: getImagePath(`svg/envelope-white.svg`),
    imgAlt: "Inbox",
    path: "/messages",
    current: false,
    navItemName: "Inbox",
    isVisibleToAdminOnly: false,
    isAvailable: true,
    isExpandable: false
  },
  {
    id: 4,
    imgSrc: getImagePath(`svg/lettings.svg`),
    imgAlt: "Lettings",
    current: false,
    navItemName: "Lettings",
    isVisibleToAdminOnly: false,
    isAvailable: true,
    isExpandable: true,
    isCollapsed: true,
    subOptions: lettingsSubOptions
  },
  {
    id: 5,
    imgSrc: getImagePath(`svg/sales-updated.svg`),
    imgAlt: "sales",
    path: "/sales",
    current: false,
    navItemName: "Sales",
    isVisibleToAdminOnly: false,
    isAvailable: true,
    isExpandable: false
  },
  {
    id: 6,
    imgSrc: getImagePath(`svg/marketing.svg`),
    imgAlt: "Marketing",
    path: "/marketing",
    current: false,
    navItemName: "Marketing",
    isVisibleToAdminOnly: false,
    isAvailable: true,
    isExpandable: false
  },
  {
    id: 7,
    imgSrc: getImagePath(`svg/contacts-icon.svg`),
    imgAlt: "Applicants",
    path: "/applicants",
    current: false,
    navItemName: "Applicants",
    isVisibleToAdminOnly: false,
    isAvailable: true,
    isExpandable: false
  }
];
