import { AccessUserPermissions } from "@constants/enum";
import { AccessControl } from "@store/auth/authSlice";

/**
 * Special function to use when `property_id` for access permission does not matter.
 * Do not use this for regular RBAC. Use isRBACGranted instead
 */
export const getIsPermissionGranted = (
  userAccessControl: AccessControl[],
  allowedPermissions: AccessUserPermissions[]
) => {
  let isPermissionGranted = false;

  for (let i = 0; i < allowedPermissions.length; i++) {
    const allowedPermission = allowedPermissions[i];
    const matchFound = userAccessControl.find(
      (accessControlObj) => accessControlObj.permission_code === allowedPermission
    );
    if (matchFound && matchFound.permission_code) {
      isPermissionGranted = true;
      break;
    }
  }

  return isPermissionGranted;
};
