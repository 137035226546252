import React from "react";
import "./MsgComponentQlAi.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface IMsgComponentProps {
  key: number;
  logo: string;
  botName: string;
  msg: string;
  citations?: string[];
}

const fixMessage = (msg: string) => {
  const updatedMsg = msg.replaceAll("\r\n\r\n", "");
  return updatedMsg;
};

export const MsgComponentQlAi: React.FC<IMsgComponentProps> = ({
  logo,
  botName,
  msg,
  citations
}) => {
  return (
    <div className="msg-container">
      <div className="msg-avatar">
        <img alt="image" src={logo} loading="lazy" className="msg-image" />
      </div>
      <div className="msg-content_wrapper">
        <div className="company_name">{botName}</div>
        <ReactMarkdown className="chat_prompt" remarkPlugins={[remarkGfm]}>
          {fixMessage(msg)}
        </ReactMarkdown>
        {citations && (
          <div>
            <p>Sources:</p>
            <ul>
              {citations?.map((c) => {
                return (
                  <li>
                    <a href={c} target="_blank" rel="noopener noreferrer">
                      {c}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MsgComponentQlAi;
