import React from "react";
import "./MsgComponent.scss";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface IMsgComponentProps {
  key: number;
  logo: string;
  botName: string;
  msg: string;
}

const fixMessage = (msg: string) => {
  const updatedMsg = msg.replaceAll("\r\n\r\n", "");
  return updatedMsg;
};

export const MsgComponent: React.FC<IMsgComponentProps> = ({ logo, botName, msg }) => {
  return (
    <div className="msg-container">
      <div className="msg-avatar">
        <img alt="image" src={logo} loading="lazy" className="msg-image" />
      </div>
      <div className="msg-content_wrapper">
        <div className="company_name">{botName}</div>
        <ReactMarkdown className="chat_prompt" remarkPlugins={[remarkGfm]}>
          {fixMessage(msg)}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default MsgComponent;
