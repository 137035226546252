// package imports
import React from "react";
import { IonGrid, IonCol } from "@ionic/react";

// local imports
import "./loginOptions.scss";
import { getImagePath } from "@utils/helpers/media.helper";

// interfaces
interface LoginOptionsProps {
  classes?: string;
  styleProps?: Record<string, unknown>;
  optionName: string;
  iconOption: string;
  hide?: boolean;
  onClick: () => void;
}
/**
 * Primary UI component for user interaction
 */
export const LoginOptions = ({
  classes,
  styleProps,
  optionName,
  iconOption = "phone",
  hide,
  onClick
}: LoginOptionsProps) => {
  const renderIcon = (iconOption: string) => {
    switch (iconOption) {
      case "phone":
        return <img src={getImagePath(`svg/phone-icon.svg`)} alt="call" />;
      case "google":
        return <img src={getImagePath(`svg/google-icon.svg`)} alt="google" />;
      case "facebook":
        return <img src={getImagePath(`svg/fb-icon.svg`)} alt="facebook" />;
      case "microsoft":
        return (
          <img
            src={getImagePath(`svg/microsoft-icon.svg`)}
            alt="microsoft-icon"
            height={"20px"}
            width={"20px"}
          />
        );
      default:
        return <img src={getImagePath(`svg/fb-icon.svg`)} alt="microsoft" />;
    }
  };
  return (
    <>
      {!hide ? (
        <IonCol
          size="12"
          className={`login-options grey800_bg ${classes}`}
          style={styleProps}
          onClick={() => onClick()}
        >
          <IonCol size="auto">{renderIcon(iconOption)}</IonCol>
          <IonGrid className="lo-text">Continue with {optionName}</IonGrid>
        </IonCol>
      ) : (
        ""
      )}
    </>
  );
};
