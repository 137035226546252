export enum TableDataType {
  monthlyAmount = "monthlyAmount",
  countdown = "countdown",
  deposit = "deposit",
  term = "term",
  name = "name",
  email = "email",
  mobile = "mobile",
  created = "created",
  action = "action"
}

export interface TableHeaderDataProps {
  id: number;
  displayName: string;
  sizeMd: string;
  apiLabel?: string;
  sortable?: boolean;
  sortBy?: string;
  customClass?: string;
  sizeSm?: string;
  sizeXs?: string;
  sizeXl?: string;
  type?: TableDataType | null;
}
