import { DURATION_TYPE } from "./properties";
import { TableDataType } from "./table";

export enum OfferStatus {
  draft = "draft",
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
  expired = "expired",
  cancelled = "cancelled",
  countered = "countered",
  closed = "closed"
}

export enum OfferToggleValue {
  CURRENT = "CURRENT",
  PAST = "PAST"
}

export const OFFER_TAB_LIST_LABELS = {
  active: "Active",
  accepted: "Accepted",
  draft: "Draft",
  all: "All"
};

export const OFFER_TAB_LIST_LABELS_URL = {
  active: "pending",
  accepted: "active",
  draft: "draft",
  all: "all"
};

// Offers tab section
export const OFFER_TAB_LIST = Object.values(OFFER_TAB_LIST_LABELS);

export const OFFER_TAB_LIST_STATUS = ["pending", "active", "archive", "all"];

export const OFFER_TAB_MAPPED_OFFER_STATUS = {
  pending: [OfferStatus.pending],
  active: [OfferStatus.accepted, OfferStatus.countered],
  archive: [OfferStatus.rejected, OfferStatus.expired, OfferStatus.cancelled, OfferStatus.closed],
  All: [
    OfferStatus.pending,
    OfferStatus.accepted,
    OfferStatus.rejected,
    OfferStatus.expired,
    OfferStatus.cancelled,
    OfferStatus.countered,
    OfferStatus.closed
  ]
};

export const PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME = {
  property_address: "Property address",
  deposit: "Deposit",
  current_offer: "Current offer",
  move_in_date: "Move-in date",
  term: "Term",
  expires_at: "Expires in",
  created_date: "Created"
};

// Keys of the fields returned in the API response
export const PENDING_OFFER_TABLE_API_LABELS = {
  property_address: "short_address",
  deposit: "is_holding_deposit_paid",
  current_offer: "amount",
  move_in_date: "move_date",
  term: "length",
  expires_at: "expires_at",
  created_date: "created_date"
};

export const ALL_OTHER_OFFER_TABLE_HEADER_DISPLAY_NAME = {
  property_address: "Property address",
  deposit: "Deposit",
  current_offer: "Current offer",
  move_in_date: "Move-in date",
  term: "Term",
  offer_stage: "Offer stage",
  created_date: "Created"
};

// Keys of the fields returned in the API response
export const ALL_OTHER_OFFER_TABLE_API_LABELS = {
  property_address: "short_address",
  deposit: "is_holding_deposit_paid",
  current_offer: "amount",
  move_in_date: "move_date",
  term: "length",
  offer_stage: "offer_stage",
  created_date: "created_date"
};

export const PENDING_OFFER_SORT_BY_OPTIONS_MOBILE = [
  {
    id: 1,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.property_address,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.property_address,
    sizeMd: "3.5",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.property_address,
    type: null
  },
  {
    id: 3,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.current_offer,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.current_offer,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.current_offer,
    type: TableDataType.monthlyAmount
  },
  {
    id: 4,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.move_in_date,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.move_in_date,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.move_in_date,
    type: null
  },
  {
    id: 5,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.term,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.term,
    sizeMd: "1",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.term,
    type: TableDataType.term
  },
  {
    id: 6,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.expires_at,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.expires_at,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.expires_at,
    type: TableDataType.countdown
  }
];

export const ALL_OTHER_OFFER_SORT_BY_FILTER_MOBILE = [
  {
    id: 1,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.property_address,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.property_address,
    sizeMd: "3.5",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.property_address,
    type: null
  },
  {
    id: 3,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.current_offer,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.current_offer,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.current_offer,
    type: TableDataType.monthlyAmount
  },
  {
    id: 4,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.move_in_date,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.move_in_date,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.move_in_date,
    type: null
  },
  {
    id: 5,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.term,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.term,
    sizeMd: "1",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.term,
    type: TableDataType.term
  }
];

// Array to render the header of the Pending offer table
export const PENDING_OFFER_TABLE_HEADERS = [
  {
    id: 1,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.property_address,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.property_address,
    sizeMd: "3.5",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.property_address,
    type: null
  },
  {
    id: 2,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.created_date,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.created_date,
    sizeMd: "1.5",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.created_date,
    type: TableDataType.created
  },
  {
    id: 3,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.current_offer,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.current_offer,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.current_offer,
    type: TableDataType.monthlyAmount
  },
  {
    id: 4,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.move_in_date,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.move_in_date,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.move_in_date,
    type: null
  },
  {
    id: 5,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.term,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.term,
    sizeMd: "1",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.term,
    type: TableDataType.term
  },
  {
    id: 6,
    displayName: PENDING_OFFER_TABLE_HEADER_DISPLAY_NAME.expires_at,
    apiLabel: PENDING_OFFER_TABLE_API_LABELS.expires_at,
    sizeMd: "2",
    sortable: true,
    sortBy: PENDING_OFFER_TABLE_API_LABELS.expires_at,
    type: TableDataType.countdown
  }
];

// Array to render the header of the Pending offer table
export const ALL_OTHER_OFFER_TABLE_HEADERS = [
  {
    id: 1,
    displayName: ALL_OTHER_OFFER_TABLE_HEADER_DISPLAY_NAME.property_address,
    apiLabel: ALL_OTHER_OFFER_TABLE_API_LABELS.property_address,
    sizeMd: "3.5",
    sortable: true,
    sortBy: ALL_OTHER_OFFER_TABLE_API_LABELS.property_address,
    type: null
  },
  {
    id: 2,
    displayName: ALL_OTHER_OFFER_TABLE_HEADER_DISPLAY_NAME.created_date,
    apiLabel: ALL_OTHER_OFFER_TABLE_API_LABELS.created_date,
    sizeMd: "1.5",
    sortable: true,
    sortBy: ALL_OTHER_OFFER_TABLE_API_LABELS.created_date,
    type: TableDataType.created
  },
  {
    id: 3,
    displayName: ALL_OTHER_OFFER_TABLE_HEADER_DISPLAY_NAME.current_offer,
    apiLabel: ALL_OTHER_OFFER_TABLE_API_LABELS.current_offer,
    sizeMd: "2",
    sortable: true,
    sortBy: ALL_OTHER_OFFER_TABLE_API_LABELS.current_offer,
    type: TableDataType.monthlyAmount
  },
  {
    id: 4,
    displayName: ALL_OTHER_OFFER_TABLE_HEADER_DISPLAY_NAME.move_in_date,
    apiLabel: ALL_OTHER_OFFER_TABLE_API_LABELS.move_in_date,
    sizeMd: "2",
    sortable: true,
    sortBy: ALL_OTHER_OFFER_TABLE_API_LABELS.move_in_date,
    type: null
  },
  {
    id: 5,
    displayName: ALL_OTHER_OFFER_TABLE_HEADER_DISPLAY_NAME.term,
    apiLabel: ALL_OTHER_OFFER_TABLE_API_LABELS.term,
    sizeMd: "1",
    sortable: true,
    sortBy: ALL_OTHER_OFFER_TABLE_API_LABELS.term,
    type: TableDataType.term
  },
  {
    id: 6,
    displayName: ALL_OTHER_OFFER_TABLE_HEADER_DISPLAY_NAME.offer_stage,
    apiLabel: ALL_OTHER_OFFER_TABLE_API_LABELS.offer_stage,
    sizeMd: "2",
    sortable: true,
    sortBy: ALL_OTHER_OFFER_TABLE_API_LABELS.offer_stage,
    type: null
  }
];

export interface OfferDetails {
  amount: number;
  created_date: string;
  deposit_amount: number;
  id: number;
  length: number;
  move_date: string;
  move_out_date: string;
  negotiation_id: string;
  offer_stage: string;
  property_id: string;
  short_address: string;
  status: string;
  updated_at: string;
  expires_at: string | null;
  is_holding_deposit_paid: boolean;
}

interface OffersCount {
  pendingOffersCount: number;
  activeOffersCount: number;
  archiveOffersCount: number;
  draftOfferCount: number;
  allOffersCount: number;
}

export interface ResponseData {
  error: string;
  offers: OfferDetails[];
  total_count: number;
  total_pages: number;
  allOffersCount?: OffersCount;
}

export interface OfferDetailsTransformed {
  short_address: string;
  deposit_amount: number;
  amount: number;
  move_date: string;
  created_date: string;
  length: number;
  property_id: string;
  status: string;
  id: number;
  offer_stage: string;
  is_holding_deposit_paid: boolean;
  updated_at?: string;
  expires_at: string | null;
}

export interface SingleOfferDetailsAgent {
  email?: string;
  first_name?: string;
  last_name?: string;
  agent_id?: string;
}

export interface OccupationDetails {
  "Employment status"?: string;
  "Gross annual income"?: string;
  Company?: string;
  "Job title"?: string;
  "Additional income"?: string;
  "Adverse credit"?: string;
  "Tax returns up-to-date"?: string;
  "Contract end date"?: string;
  University?: string;
  Course?: string;
}

export interface SingleOfferDetailsApplicants {
  id: number;
  occupation_details: OccupationDetails;
  user_id?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  country_code?: string;
  phone?: string;
  occupation?: string;
  job_title?: string;
  university?: string;
}

export enum CHILDREN_AGE_GROUP {
  zero_one = "zero_one",
  two_five = "two_five",
  six_nine = "six_nine",
  six_ten = "six_ten",
  ten_seventeen = "ten_seventeen"
}

export const CHILDREN_AGE_GROUP_MAP = {
  [CHILDREN_AGE_GROUP.zero_one]: "0-1",
  [CHILDREN_AGE_GROUP.two_five]: "2-5",
  [CHILDREN_AGE_GROUP.six_nine]: "6-9",
  [CHILDREN_AGE_GROUP.six_ten]: "6-10",
  [CHILDREN_AGE_GROUP.ten_seventeen]: "10-17"
};

export interface SingleOfferDetailsChildren {
  children_age_group: CHILDREN_AGE_GROUP[];
  no_of_children: number;
  age_group?: string;
}

export interface SingleOfferDetailsOffer {
  id: number;
  amount: string;
  counter_offer: 0 | 1;
  deposit_amount: string;
  in_advance: string;
  move_in_date: string;
  negotiation_id: string;
  no_applicants: number;
  offer_from: "applicant" | "owner";
  user_id: string;
  parking_required: 0 | 1;
  public_id: string;
  status: OfferStatus;
  term: number;
  created_date: string;
  thread_id: number;
  is_holding_deposit_paid: boolean;
  special_condition?: string;
  affordability?: string;
  intro_to_owner?: string;
  message?: string;
  offer_stage?: string;
  updated_at?: string;
  expires_at: string | null;
}

export interface SingleOfferDetailsPets {
  no_of_pets: number;
  pets_name: string[];
  type?: string;
}

export interface SingleOfferDetailsPropertyLeadPhoto {
  type: string;
  url: string;
}

export interface SingleOfferDetailsPropertyOwner {
  user_id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  country_code?: string;
  mobile?: string;
}

export interface SingleOfferDetailsProperty {
  formatted_address: string;
  id: string;
  property_ref: number;
  postcode: string;
  property_owner: SingleOfferDetailsPropertyOwner;
  property_status: "draft" | "active" | "inactive" | "pending";
  bedrooms: number | null;
  bathrooms: number | null;
  asking_price: string;
  available_from: string;
  agent_id?: string;
  lead_photo?: SingleOfferDetailsPropertyLeadPhoto;
}

export interface SingleOfferDetails {
  agent: SingleOfferDetailsAgent;
  applicants: SingleOfferDetailsApplicants[];
  children: SingleOfferDetailsChildren;
  offer: SingleOfferDetailsOffer;
  pets: SingleOfferDetailsPets;
  property: SingleOfferDetailsProperty;
  viewing_agent: SingleOfferDetailsAgent;
}

export interface SingleOfferHistory {
  added_by: string;
  affordability: string;
  amount: string;
  children: SingleOfferDetailsChildren[];
  tenants: SingleOfferDetailsApplicants[];
  pets: SingleOfferDetailsPets[];
  contract_type: string;
  public_id: string;
  intro_to_owner: string;
  counter_offer: number;
  created_date: string;
  damage_deposit_type: string;
  decline_reason: string;
  deposit_amount: string;
  id: number;
  is_holding_deposit_paid: boolean;
  length: number;
  move_date: string;
  offer_from: string;
  num_children: number;
  num_pets: number;
  no_applicants: number;
  parking_required: number;
  special_condition: string;
}

export enum SortByOptions {
  PRICE = "price",
  STATUS = "offer_status",
  STAGE = "offer_stage",
  MOVE_IN_DATE = "move_date",
  TERMS = "terms",
  CREATED_AT = "created_at",
  UPDATED_AT = "updated_at"
}

export enum SortOrderOptions {
  ASC = "asc",
  DESC = "desc"
}

export enum SortOrderCapital {
  ASC = "ASC",
  DESC = "DESC"
}
export enum DepositType {
  ALL = "ALL",
  PAID = "PAID",
  NOT_PAID = "NOT_PAID"
}

export enum OfferStages {
  accepted = "accepted",
  referencing = "referencing",
  tenancy_agreement = "tenancy_agreement",
  move_in_monies = "move_in_monies",
  move_in_preparation = "move_in_preparation",
  move_in_day = "move_in_day",
  active_tenancy = "active_tenancy"
}

export const offerStageMap = [
  {
    code: "accepted",
    value: "Accepted"
  },
  {
    code: "referencing",
    value: "Referencing"
  },
  {
    code: "tenancy_agreement",
    value: "Tenancy agreement"
  },
  {
    code: "move_in_monies",
    value: "Move-in monies"
  },
  {
    code: "move_in_preparation",
    value: "Move-in preparation"
  },
  {
    code: "move_in_day",
    value: "Move-in day"
  },
  {
    code: "active_tenancy",
    value: "Active tenancy"
  }
];

export interface Sort {
  sortBy?: SortByOptions;
  sortOrder?: SortOrderOptions;
}

export interface PaginationInterface {
  page?: number;
  limit?: number;
}

export interface FilterOptions {
  stages?: OfferStages[];
  propertyIds?: string[];
  agentIds?: string[];
  move_in_date?: string;
  expiring_in?: number[];
  terms?: number;
  send_count_only?: 0 | 1;
  send_offers_count?: 0 | 1;
  deposit_type?: DepositType;
}

export const rejectOfferOptions: Array<{ label: string; value: string }> = [
  { label: "Move-in date", value: "move_in_date" },
  { label: "Tenancy term", value: "tenancy_term" },
  { label: "Offer Price", value: "offer_price" },
  { label: "Received another offer", value: "another_offer" },
  { label: "References didn't check out", value: "references_not_checked" },
  { label: "Tenants weren't a good fit", value: "tenants_not_good" },
  { label: "Property has been let already", value: "property_let" }
];
export const cancelOfferOptions: Array<{ label: string; value: string }> = [
  { label: "Move-in date", value: "move_in_date" },
  { label: "Tenancy term", value: "tenancy_term" },
  { label: "Offer Price", value: "offer_price" },
  { label: "Received better offer", value: "better_offer" },
  { label: "References didn't check out", value: "references_not_checked" },
  { label: "Tenants weren't a good fit", value: "tenants_not_good" },
  { label: "Property has been let already", value: "property_let" }
];

export enum COUNTER_OFFER_POPUP_ACTIONS {
  APPLY = "Apply",
  CANCEL = "Cancel"
}

export interface CounterOfferDetails {
  move_in_date: string;
  length: number;
  parking_required: boolean;
  price: number;
  special_requirements: string;
  priceFrequency: DURATION_TYPE;
}

export const SPECIAL_CONDITION = {
  ROWS: 5,
  CHARACTER_LIMIT: 4000
};

export const OFFER_STAGE_DDL_MAP = [
  {
    name: "Offer negotiation",
    value: "offer_negotiation"
  },
  {
    name: "Accepted",
    value: "accepted"
  },
  {
    name: "Referencing",
    value: "referencing"
  },
  {
    name: "Tenancy agreement",
    value: "tenancy_agreement"
  },
  {
    name: "Move-in monies",
    value: "move_in_monies"
  },
  {
    name: "Move in preparation",
    value: "move_in_preparation"
  },
  {
    name: "Move-in day",
    value: "move_in_day"
  },
  {
    name: "Active tenancy",
    value: "active_tenancy"
  }
];
export const OWNER_DPS_LINK = "https://support.propertyloop.co.uk/hc/en-gb/articles/360019347197";
export const OWNER_DRS_LINK = "https://support.propertyloop.co.uk/hc/en-gb/articles/360019479278";
export const OWNER_HD_PAID_LINK =
  "https://support.propertyloop.co.uk/hc/en-gb/articles/360019179657";
export const OWNER_HD_NOT_PAID_LINK =
  "https://support.propertyloop.co.uk/hc/en-gb/articles/360019305878";

export const API_RESPONSE_TYPE = {
  success: "success",
  error: "error"
};

export const UPDATE_OFFER_CONFIRMATION_POPUP_TITLE = "Change offer stage";

export const UPDATE_OFFER_SUCCESS_MESSAGE = "Offer updated successfully";
export const UPDATE_OFFER_ERROR_MESSAGE = "Error while updating offer";

export const SPECIAL_REQUIREMENTS = "Special requirements";
export const PERSONAL_INFORMATION = "Personal information";

export const OFFER_FILTER_KEYS = {
  deposit: "deposit_type",
  move_in_date: "move_in_date",
  stage: "stages",
  term: "terms",
  expires_in: "expiring_in"
};

export interface FilterOption {
  deposit_type?: string;
  move_in_date_from?: string | null;
  move_in_date_to?: string | null;
  stage?: string[];
  term?: string;
  expires_in_from?: string;
  expires_in_to?: string;
}

export interface FilterOptionsServer {
  deposit?: string;
  move_in_date?: string;
  stage?: string;
  term?: string;
  expires_in?: string;
}

export const offerExtendDurationList = [
  "1 hour",
  "6 hours",
  "12 hours",
  "24 hours",
  "48 hours",
  "72 hours",
  "96 hours",
  "120 hours"
];
