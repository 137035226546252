// imports
import { ThunkDispatch } from "@reduxjs/toolkit";
import { Descendant, Node } from "slate";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";
import { BaseMessage } from "@sendbird/chat/message";

// local imports
import { HTTP_STATUS_CODES } from "@constants/enum/messaging-http-status-codes";
import { setSbInstance } from "@store/messages/messagesSlice";
import apiService from "@utils/apiService";
import { REACT_APP_SENDBIRD_APP_ID } from "@constants/env.constants";
import { BaseMessageData } from "@constants/messages.constant";

export async function validateCreateSingleUser(
  userId: string,
  dispatch: ThunkDispatch<unknown, unknown, any>
) {
  try {
    const validateCreateSingleUserResp = await apiService.post(
      "/v1/messages/user/validateOrCreate"
    );

    if (
      validateCreateSingleUserResp &&
      validateCreateSingleUserResp.data &&
      validateCreateSingleUserResp.data.status === HTTP_STATUS_CODES.SUCCESS
    ) {
      const sbInstance = SendbirdChat.init({
        appId: REACT_APP_SENDBIRD_APP_ID as string,
        modules: [new GroupChannelModule()]
      });

      await sbInstance.connect(userId);
      dispatch(setSbInstance(sbInstance));
    }
  } catch (error) {
    console.error("--- Error: Validate Or Create Single User ---", JSON.stringify(error));
  }
}

export const customUuid = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const serializeRichTextEditorData = (editorData: Descendant[]) => {
  return editorData.map((eValue) => Node.string(eValue)).join("\n");
};

// This util function converts all the offer links to '/offers'
// This is required because the link points to PL website and cant be opened on AMC
export const fixOfferLinksInMessages = (baseMessages: BaseMessage[]) => {
  const newBaseMessages = baseMessages.map((baseMessage) => {
    if (!baseMessage.data) return baseMessage;

    const baseMessageDataArray: BaseMessageData[] = JSON.parse(baseMessage.data);

    const newBaseMessageDataArray = baseMessageDataArray.map((data) => {
      if (data.type === "link" && data.href?.includes("/offers")) {
        data.href = "/offers";
      }
      if (!data.children) return data;

      const newChildrenArray = data.children.map((childNode) => {
        if (childNode.type === "link" && childNode.href?.includes("/offers")) {
          childNode.href = "/offers";
        }
        return childNode;
      });

      return {
        ...data,
        children: newChildrenArray
      };
    });

    return {
      ...baseMessage,
      data: JSON.stringify(newBaseMessageDataArray)
    } as BaseMessage;
  });
  return newBaseMessages;
};
