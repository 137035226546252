// imports
import { createSlice } from "@reduxjs/toolkit";
import SendbirdChat from "@sendbird/chat";
import {
  GroupChannelModule,
  MessageModule,
  ModuleNamespaces,
  PollModule
} from "@sendbird/chat/lib/__definition";

// interfaces
type SbInstance = SendbirdChat &
  ModuleNamespaces<
    [...GroupChannelModule[], MessageModule, PollModule],
    GroupChannelModule | MessageModule | PollModule
  >;

interface IMessagesSlice {
  sbInstance: SbInstance | null;
  selectedThreadId: string | null;
}

// variable declaration
const initialState: IMessagesSlice = {
  sbInstance: null,
  selectedThreadId: null
};

// messages slice
export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setSbInstance: (state, action) => {
      state.sbInstance = action.payload;
    },
    setSelectedThreadId: (state, action) => {
      state.selectedThreadId = action.payload;
    }
  }
});

export const { setSbInstance, setSelectedThreadId } = messagesSlice.actions;
export default messagesSlice.reducer;
