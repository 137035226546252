import { useEffect } from "react";

/**
 * Custom hook to listen to an event and handle unmount
 * @param eventName - name of the event to listen to
 * @param callback - callback function to call when event is fired
 * @param effectDependencies - useEffect dependency array
 * @param target - target element to listen to event on
 */
const useEventListener = (
  eventName: string,
  callback: (e: any) => void,
  effectDependencies: Array<unknown> = [],
  target: EventTarget = window
) => {
  useEffect(() => {
    target.addEventListener(eventName, callback);

    return () => {
      target.removeEventListener(eventName, callback);
    };
  }, effectDependencies);
};

export const emitEvent = (eventName: string, params: Record<string, any> = {}) => {
  const event = new CustomEvent(eventName, {
    detail: {
      ...params
    }
  });

  window.dispatchEvent(event);
};

export default useEventListener;
